import React, { useEffect, useRef } from "react";
import confetti from "canvas-confetti";

export const ConfettiEffect = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const myConfetti = confetti.create(canvasRef.current, { resize: true });

    const timeoutId = setTimeout(() => {
      (function frame() {
        myConfetti({
          particleCount: 220,
          spread: 150,
          origin: { y: 0.4 },
          colors: ["#2760ad", "#faa51a", "#ffffff", "#26db3e"],
          scalar: 0.8,
        });
      })();
    }, 1000);

    // Clean up
    return () => {
      clearTimeout(timeoutId);
      myConfetti.reset();
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      id="confetti-effect"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 201,
      }}
    />
  );
};
