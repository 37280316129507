import VideoIcon from "assets/images/icons/video-icon.svg";

export const VideoLinkContent = ({ label, redirectTo, onSetUrl }) => {
  return (
    <div
      className="attachement-btn"
      onClick={() => onSetUrl(redirectTo, label)}
      style={{ cursor: "pointer" }}
    >
      <img src={VideoIcon} alt="icon" />
      <span className="attachement-btn-txt">{label}</span>
    </div>
  );
};
