import { useQuery } from "@tanstack/react-query";
import { getSettingsById, GET_SETTINGS_BY_ID_API } from "..";

export const useSettings = ({ load = false, key = null }) => {
  const settingsInfo = useQuery({
    queryKey: [GET_SETTINGS_BY_ID_API, key],
    queryFn: () => getSettingsById(key),
    enabled: load && !!key,
  });

  return {
    settingsInfo,
  };
};
