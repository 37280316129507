import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { getCookie } from "utils";

export const getTokens = () => {
  var localTokens = JSON.parse(getCookie("_stu_user_data"));
  return localTokens;
};

export const getAssessmentToken = () => {
  return sessionStorage.getItem("assessmentToken") || "";
};

export const getSelectedProgram = () => {
  return localStorage.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;
};

export const trimText = (str, length = 35, tail = "...") => {
  if (str?.length > length) {
    return str?.substring(0, length - tail?.length) + tail;
  } else {
    return str;
  }
};

export const trimHtmlContent = (htmlContent, lettersCount) => {
  const regex = /(<([^>]+)>)/gi;
  let convertedHtml = htmlContent.replace(regex, "");
  return trimText(convertedHtml, lettersCount);
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#f93e3e",
  }).showToast();
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Done!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#4fbe87",
  }).showToast();
};

export const calculatePercentage = (total, complete) => {
  const percentage = (complete / total) * 100;
  return percentage.toFixed(0);
};

export const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = "";
  if (hours > 0) {
    result += hours + "h" + "\u00A0";
  }
  if (remainingMinutes > 0 || hours === 0) {
    result += remainingMinutes + "m";
  }

  return result.trim();
};

export const courseTaskTypeFinder = (type, flag) => {
  switch (type) {
    case 0:
      return flag ? "simulation" : "Task";
    case 1:
      return flag ? "theory" : "Reading";
    case 2:
      return flag ? "video" : "Video";
    case 3:
      return flag ? "document" : "Pdf";

    default:
      return flag ? "simulation" : "Task";
  }
};

export const clearLocalKeysWithPrefix = (
  prefix = "",
  storageType = "localStorage"
) => {
  if (prefix !== "") {
    let keys = "";

    if (storageType === "sessionStorage") {
      keys = Object.keys(sessionStorage);
    } else if (storageType === "localStorage") {
      keys = Object.keys(localStorage);
    }

    const regex = new RegExp(`^${prefix}`);

    for (const key of keys) {
      if (regex.test(key)) {
        if (storageType === "localStorage") {
          localStorage.removeItem(key);
        } else if (storageType === "sessionStorage") {
          sessionStorage.removeItem(key);
        }
      }
    }
  }
};

export const clearHistoryAndRedirect = (redirectTo) => {
  window.history.pushState(null, null, redirectTo);
  window.location.replace(redirectTo);
};
