import React, { useEffect } from "react";
import { LoadingSpinner, PageLayout } from "shared";
import { useAssessment, ResultCard, PublishSoonCard } from "..";
import { useQuestionareContext } from "master";
import { useNavigate } from "react-router-dom";

export const AssessmentResult = () => {
  const questionnaireContext = useQuestionareContext();
  const navigate = useNavigate();
  const { disableBackButton, setIsModalOpen } = questionnaireContext;

  useEffect(() => {
    disableBackButton();
  }, []);

  const assessmentId = JSON.parse(sessionStorage.getItem("assessmentId"));
  const assessmentMasterId = sessionStorage.getItem(
    `assessmentMasterId_${assessmentId}`
  );
  const { assessmentResultQuery } = useAssessment({
    load: false,
    assessmentMasterId,
  });

  const { data: assessmentResult, isLoading } = assessmentResultQuery;

  const errorDetails =
    assessmentResultQuery && assessmentResultQuery?.error?.response;

  useEffect(() => {
    if (assessmentResultQuery?.error) {
      if (errorDetails?.status === 405) {
        setIsModalOpen((draft) => {
          draft = {
            type: "tokenExpired",
            isOpen: true,
            message: errorDetails?.data?.message,
          };
          return draft;
        });
      }
    }
  }, [assessmentResultQuery?.error]);

  const isPassedAssessment =
    assessmentResult && assessmentResult?.resultStatus === 4;
  const isFailedAssessment =
    assessmentResult && assessmentResult?.resultStatus === 5;
  const isAwaitingResult =
    assessmentResult && assessmentResult?.resultStatus === 6;

  const goToDashboard = () => {
    sessionStorage.removeItem(`assessmentData_${assessmentId}`);
    sessionStorage.removeItem(`currentQuestionIndex_${assessmentId}`);
    sessionStorage.removeItem(`assessmentMasterId_${assessmentId}`);
    sessionStorage.removeItem("markedQuestions");
    sessionStorage.removeItem("slotId");
    sessionStorage.removeItem("attemptId");
    sessionStorage.removeItem("assessmentId");
    sessionStorage.removeItem("assessmentToken");
    const isFromMobil = sessionStorage.getItem("isFromMobileApp");
    if (!!isFromMobil) {
      navigate("?assessmentCompleted");
    } else {
      window.close();
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <PageLayout
      isPageHeaderRequired={false}
      isRowRequired={false}
      isScrollable={false}
      isFullWidthContainer={false}
    >
      {(isPassedAssessment || isFailedAssessment) && (
        <ResultCard
          assessmentResult={assessmentResult}
          goToDashboard={goToDashboard}
        />
      )}
      {isAwaitingResult && (
        <PublishSoonCard
          message={assessmentResult?.resultStatusStr}
          goToDashboard={goToDashboard}
        />
      )}
    </PageLayout>
  );
};
