import React from "react";
import { Spinner } from "reactstrap";

export const LoadingSpinner = ({ marinHeight = "120px" }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: `calc(100dvh - ${marinHeight})` }}
    >
      <Spinner color="secondary">Loading...</Spinner>
    </div>
  );
};
