import React from "react";
import { SpinnerComponent } from "shared";

export const StudentSpecificDataList = ({
  studentSpecificData,
  studentSpecificDataQuery,
}) => {
  return (
    <div>
      {studentSpecificData ? (
        studentSpecificData.map((element, index) => {
          return (
            <>
              {Object.entries(element).map(([key, value]) => (
                <div className="data-row d-flex mb-2" key={`${key}_${index}`}>
                  <span className="pe-4">{key}</span>
                  <strong>{value}</strong>
                </div>
              ))}
              {index !== studentSpecificData.length - 1 && <hr />}
            </>
          );
        })
      ) : studentSpecificDataQuery?.isLoading ? (
        <SpinnerComponent />
      ) : (
        <span>No data found!</span>
      )}
    </div>
  );
};
