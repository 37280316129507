import React from "react";
import { MainHeader, PageLayout } from "shared";
import { TermsCondition } from ".";

export const TermsAndConditions = () => {
  return (
    <>
      <MainHeader />
      <PageLayout
        title="Terms And Conditions"
        breadcrumb={[{ label: "Terms And Conditions" }]}
      >
        <TermsCondition />
      </PageLayout>
    </>
  );
};
