import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAssessmentList, GET_ASSESSMENT_LIST } from "..";

export const useAssessmentQuery = ({ load = false, programId = null }) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_LIST, programId],
    queryFn: () => getAssessmentList(programId),
    enabled: load && !!programId,
    refetchOnWindowFocus: true,
  });
};
