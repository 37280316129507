import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
  CourseList,
  CourseGroupList,
  CourseTask,
  CourseTaskDetailsMobileView,
} from ".";
import "./styles/coursetask.style.css";
import { PageNotFound } from "master";

export const LearningMaterials = ({ type = null }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (type === null) {
      navigate("/program");
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/program" />} />
      <Route element={<CourseGroupList type={type} />} path={"/coursegroup"} />
      <Route element={<CourseList type={type} />} path={"/courseslist"} />
      <Route
        element={<CourseTask type={type} />}
        path={"/courseslist/:courseId"}
      />
      <Route
        element={<CourseTaskDetailsMobileView />}
        path={"/courseslist/:courseId/view"}
      />
      <Route element={<PageNotFound />} path={"/*"} />
    </Routes>
  );
};
