import { useQuery } from "@tanstack/react-query";
import {
  GET_PROGRAM_CERTIFICATE,
  GET_STUDENT_BATCH_CERTIFICATE,
  getProgramCertificates,
  getStudentBatchCertificate,
} from "..";

export const useStudentBatchCertificateQuery = ({
  studentBatchId = "",
  programId = "",
  load = false,
  isAllCertificate,
}) => {
  const queryFn = isAllCertificate
    ? () => getProgramCertificates(programId)
    : () => getStudentBatchCertificate(studentBatchId);

  const queryKey = isAllCertificate
    ? [GET_PROGRAM_CERTIFICATE, programId]
    : [GET_STUDENT_BATCH_CERTIFICATE, studentBatchId];

  return useQuery({
    queryKey,
    queryFn,
    enabled: load,
  });
};
