import React, { useMemo } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { trimHtmlContent } from "utils";
import "react-quill/dist/quill.snow.css";
import "../styles/notification.styles.css";

export const NotificationIndividualItem = ({
  data = {},
  trim = false,
  viewAllNotification,
}) => {
  const { title, content, localCreatedDate, programName } = data;
  const dateFormatted = useMemo(
    () =>
      moment(localCreatedDate, "DD-MMM-YYYY HH:mm:ss A").format(
        "DD MMM, YYYY [at] hh:mm A"
      ),
    [localCreatedDate]
  );

  let parsedContent = trim ? trimHtmlContent(content, 130) : parse(content);

  return (
    <li className="notification-item" onClick={viewAllNotification}>
      <a>
        <h5>{title}</h5>
        <p className="text-break">{parsedContent}</p>
        <div className="notification-item-footer">
          <div className="notification-badges">
            {programName && (
              <span className="notification-badge">{programName}</span>
            )}
          </div>
          <span className="notifiation-date">{dateFormatted}</span>
        </div>
      </a>
    </li>
  );
};
