import React from "react";
import { FormFeedback } from "reactstrap";
import { PasswordToggle } from "shared";
import InputControl from "shared/components/InputControl";

export const ChangePasswordModalBody = ({
  validator,
  changePasswordPayload,
  setChangePasswordPayload,
  forceUpdate,
  handlePassword,
  showPassWord,
}) => {
  const handleBlur = (e) => {
    const { name } = e.target;
    validator.current.showMessageFor(name);
    if (name === "currentPassword") {
      forceUpdate(2);
    } else if (name === "newPassword") {
      forceUpdate(3);
    } else {
      forceUpdate(4);
    }
  };

  return (
    <div className="mt-4 text-start ">
      <div className="position-relative form-group has-icon-right mb-3 change_password">
        <label className="form-label" htmlFor="curr_pass">
          Current password
        </label>
        <InputControl
          id="curr_pass"
          className="form-control"
          placeholder="Current password"
          name="currentPassword"
          type={showPassWord.currentPassword ? "text" : "password"}
          value={changePasswordPayload.currentPassword}
          onChange={(e) => {
            setChangePasswordPayload((draft) => {
              draft.currentPassword = e.target.value;
            });
          }}
          invalid={validator.current.message(
            "currentPassword",
            changePasswordPayload.currentPassword,
            "required|min:8|upper|lower|digit|special"
          )}
          onBlur={handleBlur}
        />
        <PasswordToggle
          passwordShown={showPassWord.currentPassword}
          togglePassword={() => handlePassword("currentPassword")}
        />
        <FormFeedback>
          {validator.current.message(
            "currentPassword",
            changePasswordPayload.currentPassword,
            "required|min:8|upper|lower|digit|special"
          )}
        </FormFeedback>
      </div>
      <div className="position-relative form-group has-icon-right mb-3 change_password">
        <label className="form-label" htmlFor="new_pass">
          New password
        </label>
        <InputControl
          id="new_pass"
          className="form-control"
          placeholder="New password"
          name="newPassword"
          type={showPassWord.newPassword ? "text" : "password"}
          value={changePasswordPayload.newPassword}
          onChange={(e) => {
            setChangePasswordPayload((draft) => {
              draft.newPassword = e.target.value;
            });
          }}
          invalid={validator.current.message(
            "newPassword",
            changePasswordPayload.newPassword,
            "required|min:8|upper|lower|digit|special"
          )}
          onBlur={handleBlur}
        />
        <PasswordToggle
          passwordShown={showPassWord.newPassword}
          togglePassword={() => handlePassword("newPassword")}
        />
        <FormFeedback>
          {validator.current.message(
            "newPassword",
            changePasswordPayload.newPassword,
            "required|min:8|upper|lower|digit|special"
          )}
        </FormFeedback>
      </div>
      <div className="position-relative form-group has-icon-right mb-3 change_password">
        <label className="form-label" htmlFor="conf_pass">
          Confirm password
        </label>
        <InputControl
          id="conf_pass"
          className="form-control"
          placeholder="Confirm password"
          name="confirmPassword"
          type={showPassWord.confirmPassword ? "text" : "password"}
          value={changePasswordPayload.confirmPassword}
          onChange={(e) => {
            setChangePasswordPayload((draft) => {
              draft.confirmPassword = e.target.value;
            });
          }}
          invalid={validator.current.message(
            "confirmPassword",
            changePasswordPayload.confirmPassword,
            "required"
          )}
          onBlur={handleBlur}
        />
        <PasswordToggle
          passwordShown={showPassWord.confirmPassword}
          togglePassword={() => handlePassword("confirmPassword")}
        />
        {changePasswordPayload.confirmPassword &&
          changePasswordPayload.confirmPassword !==
            changePasswordPayload.newPassword && (
            <div
              style={{ fontSize: "14px", marginTop: "2px" }}
              className="text-danger"
            >
              Password must be same!
            </div>
          )}
        <FormFeedback>
          {validator.current.message(
            "confirmPassword",
            changePasswordPayload.confirmPassword,
            "required"
          )}
        </FormFeedback>
      </div>
    </div>
  );
};
