import React, { useEffect } from "react";
import parse from "html-react-parser";
import {
  CourseTheoryAttachment,
  StudentSpecificDataBtn,
  StudentSpecificDataList,
} from ".";
import { ModalLayout } from "shared";
import StudentSpecificDataImg from "assets/images/student-specific-data-img.svg";
import { useImmer } from "use-immer";

export const CourseTheory = ({
  studentTheory = [],
  studentTheoryDetails,
  modalState,
  onOpenModal,
  onCloseModal,
  studentSpecificDataQuery,
}) => {
  const { name, description, studentDataDisplayName } = studentTheoryDetails;
  const [studentSpecificData, setStudentSpecificData] = useImmer(null);

  useEffect(() => {
    if (studentSpecificDataQuery?.data) {
      setStudentSpecificData(
        studentSpecificDataQuery?.data
          ? studentSpecificDataQuery.data.map((item) => {
              if (item.endsWith(",}")) {
                const element = item.replace(/,(?=[^,]*$)/, "");
                return JSON.parse(element);
              } else {
                return JSON.parse(item);
              }
            })
          : null
      );
    }
  }, [studentSpecificDataQuery?.data]);

  return (
    <div className="col-12 single-page-content-block">
      <div className="content-wrapper">
        <div className="content-block scrollbar-style">
          {name && <h2>{name}</h2>}
          {description && (
            <div className="mb-5">{description && parse(description)}</div>
          )}
          {studentTheory?.map((element, index) => {
            return (
              <div
                className=" mb-4 w-100"
                key={`course-section-attachment-${index}`}
              >
                <h4 className="my-3 text-dark">{element?.title}</h4>
                <div>{element?.description && parse(element?.description)}</div>
                <div className="d-flex justify-content-start flex-wrap">
                  <div className="attachments-block">
                    {element?.courseContents?.map((content) => {
                      return <CourseTheoryAttachment content={content} />;
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!!studentDataDisplayName && (
          <div className="content-footer justify-content-start">
            <StudentSpecificDataBtn
              onOpenModal={onOpenModal}
              studentDataDisplayName={studentDataDisplayName}
            />
          </div>
        )}
      </div>
      {modalState?.isOpen &&
        modalState?.actionFrom === "student_specific_data" && (
          <ModalLayout
            isOpen={modalState.isOpen}
            title={studentDataDisplayName}
            iconClass={"modal-icon-big"}
            modalStyle={{ maxWidth: "450px" }}
            icon={StudentSpecificDataImg}
            modalBody={
              <StudentSpecificDataList
                studentSpecificData={studentSpecificData}
                studentSpecificDataQuery={studentSpecificDataQuery}
              />
            }
            modalButtonGroup={
              studentSpecificDataQuery?.isLoading ? (
                <></>
              ) : (
                <button
                  type="button"
                  onClick={onCloseModal}
                  className="btn btn-primary"
                >
                  Ok
                </button>
              )
            }
          />
        )}
    </div>
  );
};
