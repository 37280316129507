import React from "react";
import parse from "html-react-parser";
import { usePrivacy } from "..";
import { LoadingSpinner } from "shared";

export const PrivacyAndPolicy = () => {
  const { settingsInfo } = usePrivacy({ load: true, key: "page_privacy" });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return <p>{data ? parse(data?.content) : ""}</p>;
};
