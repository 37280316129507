import { useImmer } from "use-immer";
import { getForgotPassword } from "..";
import { useMutation } from "@tanstack/react-query";
import { useModal } from "shared";

export const useForgotPassword = () => {
  const { modalState, onOpenModal, onCloseModal } = useModal();
  const [forgotPassEmail, setForgotPassEmail] = useImmer({
    email: "",
    isEmailSend: false,
  });

  const forgotPasswordAuth = useMutation({
    mutationFn: getForgotPassword,
    onSuccess: () => {
      setForgotPassEmail((draft) => {
        draft.isEmailSend = true;
        return draft;
      });
    },
    onError: (e) => {
      onOpenModal({
        actionFrom: "invalidEmail",
        info: e.response.data,
      });
    },
  });

  return {
    forgotPassEmail,
    setForgotPassEmail,
    forgotPasswordAuth,
    modalState,
    onCloseModal,
  };
};
