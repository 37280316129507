import React from "react";
import { Navigate } from "react-router-dom";
import { useAppScope } from "master";

export const ProtectedRoute = ({ children, redirectTo }) => {
  const {
    AppState: { token, user },
  } = useAppScope();

  return token ? children : <Navigate to={user ? redirectTo : "/login"} />;
};
