import { Axios, queryBuilder } from "utils";

export const getCourseTaskGroup = async ({ studentBatchId, type }) => {
  const res = await Axios.get(
    `v2/portal/Programs/batches/${studentBatchId}/courses/${type}/group-details`
  );
  return res.data;
};

export const getCourse = async ({ studentBatchId, type, courseGroupId }) => {
  const queryString = queryBuilder({
    courseGroupId,
  });
  const res = await Axios.get(
    `/v2/portal/Programs/batches/${studentBatchId}/courses/${type}?${queryString}`
  );
  return res.data;
};

export const getCourseTaskList = async (programId, courseId) => {
  const res = await Axios.get(
    `v2/portal/Programs/${programId}/courses/${courseId}/Tasks`
  );
  return res.data;
};

export const getCourseTaskDetails = async (courseTaskId) => {
  const res = await Axios.get(`v2/portal/CourseTasks/${courseTaskId}/details`);
  return res.data;
};

export const generateSimulationToken = async ({ taskId, refreshToken }) => {
  const res = await Axios.get(
    `/CourseTasks/GenerateSimulationToken/${taskId}?refreshToken=${refreshToken}`
  );
  return res.data;
};

export const updateCourseTaskStatus = async ({ studentTaskId, taskStatus }) => {
  const res = await Axios.put(
    `/v2/portal/courseTasks/${studentTaskId}/update/${taskStatus}`
  );
  return res.data;
};

export const getStudentSpecificData = async (programId, courseId) => {
  const res = await Axios.get(
    `/v2/portal/Programs/${programId}/courses/${courseId}/student-specific-data`
  );
  return res.data;
};
