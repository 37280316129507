import React from "react";
import { PageLayout } from "shared";
import { NotificationItems } from ".";

export const Notifications = () => {
  return (
    <PageLayout title="Notifications" breadcrumb={[{ label: "Notifications" }]}>
      <NotificationItems />
    </PageLayout>
  );
};
