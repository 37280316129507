import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { GET_COURSE_TASK_LIST, updateCourseTaskStatus } from "..";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useCourseTaskListQuery,
  useCourseTaskDetailsQuery,
  useStudentSpecificDataQuery,
} from ".";
import { Axios, errorFormatter, getCookie } from "utils";
import { useModal } from "shared";

export const useCourseTask = ({ load = false, courseId = "" }) => {
  const queryClient = useQueryClient();
  const { modalState, onOpenModal, onCloseModal } = useModal();

  let selectedProgram = localStorage.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;
  let { name: selectedProgramName, id: programId } =
    !!selectedProgram && selectedProgram;

  let selectedCourseGroup = sessionStorage.getItem("courseGroup")
    ? JSON.parse(sessionStorage.getItem("courseGroup"))
    : null;
  let { name: courseGroupName } = !!selectedCourseGroup && selectedCourseGroup;

  let courseListStorage = sessionStorage.getItem("courseList")
    ? JSON.parse(sessionStorage.getItem("courseList"))
    : null;
  let { hasCourseGroup, displayName, courseType } =
    !!courseListStorage && courseListStorage;

  const localData = JSON.parse(getCookie("_stu_user_data"));
  const { refreshToken } = localData;

  const localStorageTask = sessionStorage.getItem("currentTask")
    ? JSON.parse(sessionStorage.getItem("currentTask"))
    : null;
  const [currentCourseTask, setCurrentCourseTask] = useImmer(
    localStorageTask || null
  );
  const [currentCourseTaskIndex, setCurrentCourseTaskIndex] = useImmer(null);

  const courseTaskList = useCourseTaskListQuery({ load, programId, courseId });
  const { data } = courseTaskList;
  const { name, selectedstudentTasks, studentDataDisplayName, courseSections } =
    !!data && data;
  const [studentTasks, setStudentTasks] = useImmer(selectedstudentTasks || []);
  const [studentTheory, setStudentTheory] = useImmer(courseSections || []);
  const [studentTheoryDetails, setStudentTheoryDetails] = useImmer({
    name: "",
    description: "",
    studentDataDisplayName: "",
  });

  const courseTaskDetails = useCourseTaskDetailsQuery({
    load: load && !!currentCourseTask?.courseTaskId,
    courseTaskId: currentCourseTask?.courseTaskId,
  });

  const studentSpecificDataQuery = useStudentSpecificDataQuery({
    load:
      modalState?.isOpen && modalState?.actionFrom === "student_specific_data",
    courseId,
    programId,
  });

  const onCourseTaskCardClick = (currentTask) => {
    setCurrentCourseTask(currentTask);
    let newCurrentIndex = studentTasks?.findIndex(
      (e) => e?.courseTaskId === currentTask?.courseTaskId
    );
    setCurrentCourseTaskIndex(newCurrentIndex);
    sessionStorage.setItem("currentTask", JSON.stringify(currentTask));
  };

  useEffect(() => {
    if (courseTaskList?.data?.selectedstudentTasks) {
      setStudentTasks(courseTaskList?.data?.selectedstudentTasks);
    }
    if (courseTaskList?.data?.courseSections) {
      setStudentTheory(courseTaskList?.data?.courseSections);
      setStudentTheoryDetails((draft) => {
        draft.name = courseTaskList?.data?.name;
        draft.description = courseTaskList?.data?.description;
        draft.studentDataDisplayName =
          courseTaskList?.data?.studentDataDisplayName;
        return draft;
      });
    }
  }, [courseTaskList?.data]);

  const updateCourseTasksStatus = useMutation({
    mutationFn: updateCourseTaskStatus,
    onSuccess: () => {
      queryClient.invalidateQueries([
        GET_COURSE_TASK_LIST,
        programId,
        courseId,
      ]);
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  const onStartSimulation = async () => {
    const newWindow = window.open("/starting-simulation", "_blank");
    if (!newWindow) {
      alert("Pop-up blocked. Please allow pop-ups for this site.");
      return;
    }

    try {
      const { id } = currentCourseTask;
      const res = await Axios.get(
        `/CourseTasks/GenerateSimulationToken/${id}?refreshToken=${refreshToken}`
      );
      if (res.status === 200) {
        newWindow.location.href = `${courseTaskDetails?.data?.endPoint}?taskId=${id}&token=${res.data}&callback=${process.env.REACT_APP_BASE_URL}`;
      } else {
        throw Error;
      }
    } catch (err) {
      newWindow.close();
    }
  };

  const onPrevTask = () => {
    if (currentCourseTaskIndex) {
      onCourseTaskCardClick(studentTasks[currentCourseTaskIndex - 1]);
    }
  };

  const onNextTask = () => {
    if (currentCourseTaskIndex || currentCourseTaskIndex === 0) {
      onCourseTaskCardClick(studentTasks[currentCourseTaskIndex + 1]);
    }
  };

  return {
    name,
    studentTasks,
    courseTaskList,
    selectedProgramName,
    hasCourseGroup,
    displayName,
    selectedProgramName,
    courseGroupName,
    onCourseTaskCardClick,
    courseTaskDetails,
    courseType,
    currentCourseTask,
    updateCourseTasksStatus,
    onStartSimulation,
    setCurrentCourseTask,
    studentDataDisplayName,
    modalState,
    onOpenModal,
    onCloseModal,
    studentSpecificDataQuery,
    studentTheory,
    currentCourseTaskIndex,
    setCurrentCourseTaskIndex,
    onPrevTask,
    onNextTask,
    studentTheoryDetails,
  };
};
