import React, { forwardRef } from "react";
import { FormFeedback } from "reactstrap";
import InputControl from "shared/components/InputControl";

export const ConfirmRegistrationModalBody = forwardRef((props, ref) => {
  const { regNo, setRegNo, activateBatchMutation } = props;
  return (
    <div className="mb-3 modal-input-field">
      <label className="form-label" htmlFor="reg-no">
        Enter your Institute registration number
      </label>
      <InputControl
        type="text"
        id="reg-no"
        className="form-control"
        name="regNo"
        value={regNo ? regNo : ""}
        onChange={(e) => {
          setRegNo(e.target.value);
        }}
        disabled={activateBatchMutation.isPending}
        invalid={ref.current.message(
          "InstituteRegistrationNumber",
          regNo,
          "required"
        )}
      />
      <FormFeedback style={{ textAlign: "start" }}>
        {ref.current.message("InstituteRegistrationNumber", regNo, "required")}
      </FormFeedback>
    </div>
  );
});
