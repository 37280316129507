import { useQuery } from "@tanstack/react-query";
import { GET_PROFILE_DETAILS, getProfile } from "..";

export const useProfileQuery = ({ load = false }) => {
  return useQuery({
    queryKey: [GET_PROFILE_DETAILS],
    queryFn: () => getProfile(),
    enabled: load,
  });
};
