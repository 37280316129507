import { useQuery } from "@tanstack/react-query";
import { GET_COURSE_TASK_DETAILS, getCourseTaskDetails } from "..";

export const useCourseTaskDetailsQuery = ({
  load = false,
  courseTaskId = "",
}) => {
  return useQuery({
    queryKey: [GET_COURSE_TASK_DETAILS, courseTaskId],
    queryFn: () => getCourseTaskDetails(courseTaskId),
    enabled: !!courseTaskId && load,
  });
};
