import React from "react";
import { Modal, ModalBody } from "reactstrap";
import confirmIcon from "assets/images/assessment-submit-confirm-graphics.svg";

export const AnswerSubmitConfirmModal = ({
  isOpen,
  onCancel,
  size,
  children,
  onSubmit,
  isSubmittingAnswers,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      size={size}
      {...rest}
      className="common-modal modal__answer-submit-confirm"
    >
      <ModalBody>
        <div className="common-modal-content">
          <img src={confirmIcon} className="modal-icon" />
          <p>Are you sure to submit the test?</p>
          <div className="modal-action">
            <div className="button-group d-flex justify-content-center align-items-center">
              <button
                onClick={onCancel}
                disabled={isSubmittingAnswers}
                className="btn btn-secondary"
              >
                Close
              </button>
              <button
                onClick={onSubmit}
                disabled={isSubmittingAnswers}
                className="btn btn-primary"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
