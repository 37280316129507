import { Axios } from "utils";

export const getNotifications = async () => {
  const res = await Axios.get("/v2/portal/Students/notifications");
  return res.data;
};

export const getFilteredNotifications = async (unreadOnly) => {
  const res = await Axios.get(
    `/v2/portal/Students/notifications?unreadOnly=${unreadOnly}`
  );
  return res.data;
};

export const createOpenedNotification = async (payload) => {
  const res = await Axios.post(
    "/v2/portal/Students/notifications/opened",
    payload
  );
  return res.data;
};
