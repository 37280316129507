import React from "react";
import { useProgramQuery } from ".";

export const useProgram = ({ load = false }) => {
  let selectedProgram = localStorage.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;
  let { studentBatchId, name } = !!selectedProgram && selectedProgram;

  const programDetails = useProgramQuery({ load, studentBatchId });

  return {
    studentBatchId,
    name,
    programDetails,
  };
};
