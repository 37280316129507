import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { OfflineIcon, OnlineIcon } from "..";

export const NetworkStatusMessage = ({ isOnline, onSubmit }) => {
  return (
    <Modal
      isOpen={true}
      className="common-modal modal__timeout network-info-modal"
    >
      <ModalBody>
        <div className="common-modal-content">
          <div className="network-modal-icon">
            {isOnline ? <OnlineIcon /> : <OfflineIcon />}
          </div>
          <h3>{isOnline ? "Connection Restored" : "Connection Lost"}</h3>
          <div className="network-modal-txt">
            {isOnline
              ? "Your progress is synced. Continue your assessment. Keep up the great work!"
              : "No need to worry, your progress is saved. We'll sync when you're back online. You can Continue your assessment."}
          </div>
          {!isOnline && (
            <div className="modal-action">
              <button onClick={onSubmit} className="btn btn-primary px-4">
                Continue assessment
              </button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
