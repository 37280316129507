import { useQuery } from "@tanstack/react-query";
import { GET_HELP, getProgramHelps } from "..";

export const useHelpQuery = ({ programId, load }) => {
  return useQuery({
    queryKey: [GET_HELP],
    queryFn: () => getProgramHelps(programId),
    enabled: !!programId && !!load,
  });
};
