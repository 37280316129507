import React from "react";
import { useImmer } from "use-immer";
import { supportTicket } from "..";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { successMessage, errorFormatter } from "utils";

const initialState = {
  name: "",
  registrationId: "",
  email: "",
  phonenumber: "",
  subject: "",
  message: "",
};

export const useSupportTicket = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useImmer(false);
  const [ticketValues, setTicketValues] = useImmer(initialState);

  const supportTickets = useMutation({
    mutationFn: supportTicket,
    onSuccess: () => {
      successMessage("Support ticket has been submitted!");
      navigate("/", { replace: true });
    },
    onError: (data) => {
      errorFormatter(data);
    },
  });

  return {
    ticketValues,
    setTicketValues,
    supportTickets,
    isModalOpen,
    setIsModalOpen,
  };
};
