import React, { useCallback } from "react";
import { QuestionModalItem } from "..";
import { useQuestionareContext } from "master";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "shared";

export const QuestionsAllMobile = () => {
  const navigate = useNavigate();
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    currentQuestionIndex,
    onChangeSection,
    setCurrentQuestionIndex,
    getFilteredQuestions,
    markedQuestions,
    getCurrentSectionIndex,
    getCurrentQuestionIndex,
    setIsModalOpen,
  } = questionnaireContext;

  const currentSectionQuestions = getFilteredQuestions(
    currentQuestionIndex.sectionIndex
  );

  const navigateQuestionHandle = useCallback(
    (questionNumber, sectionId) => {
      const sectionIndex = getCurrentSectionIndex(sectionId);
      const questionIndex = getCurrentQuestionIndex(questionNumber);
      setCurrentQuestionIndex((draft) => {
        draft = {
          sectionIndex,
          questionIndex,
        };
        return draft;
      });
      navigate("/program/assessment/start");

      setIsModalOpen((draft) => {
        draft = {
          type: "",
          isOpen: false,
        };
        return draft;
      });
    },
    [currentQuestionIndex]
  );

  return (
    <PageLayout isPageHeaderRequired={false}>
      <>
        <div className="question-all-header-mob">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3>All Questions View</h3>
              </div>
            </div>
          </div>
        </div>
        <section className="question-page-content-mob">
          <div className="container">
            <div className="row">
              <div className="question-sections-group">
                <div className="question-header-btn-group scrollbar-style-horizontal">
                  {questionnaire &&
                    questionnaire?.sections?.map((section, index) => (
                      <button
                        key={`question-sectionmob-page-${section?.sectionId}`}
                        onClick={() => onChangeSection(section?.sectionId)}
                        className={`question-header-btn ${
                          currentQuestionIndex?.sectionIndex === index
                            ? "active"
                            : ""
                        }`}
                      >
                        {section?.name}
                      </button>
                    ))}
                </div>
              </div>
              <div className="question-page-content-holder-mob">
                <div className="question-content-wrapper scrollbar-style">
                  <ul className="modal-questions-list">
                    {currentSectionQuestions &&
                      currentSectionQuestions?.map((question, index) => {
                        return (
                          <QuestionModalItem
                            key={`preview-mob-question-modal-${question?.questionNumber}-${index}`}
                            question={question ? question : []}
                            index={index}
                            navigateQuestionHandle={navigateQuestionHandle}
                            markedQuestions={markedQuestions}
                          />
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </PageLayout>
  );
};
