import React from "react";
import parse from "html-react-parser";
import { CourseTheoryAttachment } from ".";

export const CourseTaskTheory = ({
  title,
  description,
  sectionContents,
  taskSections,
  index,
}) => {
  return (
    <div className=" mb-4 w-100">
      <h4 className="my-3 text-dark">{title || ""}</h4>
      <div>{description && parse(description)}</div>
      <div className="d-flex justify-content-start flex-wrap">
        <div className="attachments-block">
          {sectionContents?.map((content) => {
            return <CourseTheoryAttachment content={content} />;
          })}
        </div>
      </div>
      {taskSections?.length - 1 !== index && <hr />}
    </div>
  );
};
