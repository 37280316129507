import React from "react";

export const CommonProgressBar = ({
  width,
  label = null,
  hasPassed = false,
}) => {
  return (
    <div className="common-progress-bar">
      <div className="progress-track">
        <div
          className={`progress-highlight ${!hasPassed ? "" : "failed"}`}
          style={{
            width: `${width}%`,
          }}
        ></div>
      </div>
      {label && <div className="progress-label-txt">{label}</div>}
    </div>
  );
};
