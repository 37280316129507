import React from "react";
import ReactPlayer from "react-player";
import { Modal, ModalBody } from "reactstrap";
import "../styles/mediaPreview.styles.css";

export const MediaPreviewModal = ({
  isOpen,
  onCancel,
  url = "",
  mediaType = null,
}) => {
  const mediaViewer = (type) => {
    switch (type) {
      case 0:
        return <img className="media-preview-img" src={url} alt="media" />;
      case 1:
        return (
          <ReactPlayer
            className="media-preview-video"
            width="100%"
            height="auto"
            controls
            url={url}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      className="media-preview-modal"
      isOpen={isOpen}
      toggle={onCancel}
      size="lg"
      centered={true}
    >
      <ModalBody className="p-0 position-relative">
        <button className="media-modal-close" onClick={onCancel}>
          <svg viewBox="0 0 512 512" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                id="add"
                fill="#fff"
                transform="translate(134.248389, 134.248389)"
              >
                <path
                  d="M213.333333,2.84217094e-14 L243.503223,30.1698893 L151.921,121.751 L243.503223,213.333333 L213.333333,243.503223 L121.751,151.921 L30.1698893,243.503223 L2.84217094e-14,213.333333 L91.582,121.751 L2.84217094e-14,30.1698893 L30.1698893,2.84217094e-14 L121.751,91.582 L213.333333,2.84217094e-14 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </svg>
        </button>
        <div className="media-content-holder">{mediaViewer(mediaType)}</div>
      </ModalBody>
    </Modal>
  );
};
