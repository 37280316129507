import { useMutation } from "@tanstack/react-query";
import { useImmer } from "use-immer";
import { changePassword } from "..";
import { errorFormatter, successMessage } from "utils";
import { useAuthenticate } from "master";

export const usePassword = (props) => {
  const [showPassWord, setShowPassword] = useImmer({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const { onUserSignOut } = useAuthenticate();
  const [changePasswordPayload, setChangePasswordPayload] = useImmer({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changePasswordAuth = useMutation({
    mutationFn: changePassword,
    onError: (e) => {
      errorFormatter(e);
    },
    onSuccess: (e) => {
      props.onCloseModal();
      successMessage("Your password has been changed. Please login again!");
      setChangePasswordPayload((draft) => {
        draft.currentPassword = "";
        draft.newPassword = "";
        draft.confirmPassword = "";
        return draft;
      });
      onUserSignOut();
    },
  });

  const handleBack = () => {
    props.onCloseModal();
    setChangePasswordPayload((draft) => {
      draft.currentPassword = "";
      draft.newPassword = "";
      draft.confirmPassword = "";
      return draft;
    });
  };

  const handlePassword = (type) => {
    setShowPassword((draft) => {
      draft[type] = !draft[type];
    });
  };

  return {
    handleBack,
    showPassWord,
    handlePassword,
    changePasswordAuth,
    changePasswordPayload,
    setChangePasswordPayload,
  };
};
