import React from "react";
import { YouTubePlayer } from "shared";

export const CourseTaskVideo = ({ contentType, fileURL }) => {
  return (
    <div className="iframe-container mb-4">
      {contentType === 0 ? (
        <iframe className="responsive-iframe" src={fileURL} />
      ) : contentType === 1 ? (
        <YouTubePlayer videoUrl={fileURL} />
      ) : (
        <video
          controls
          controlsList="nodownload"
          disablePictureInPicture
          className="responsive-iframe"
        >
          <source src={fileURL} />
        </video>
      )}
    </div>
  );
};
