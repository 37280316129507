import { useProfileQuery } from "modules";
import { useModal } from ".";

export const useProfileDropDown = ({ load = false }) => {
  const { modalState, onOpenModal, onCloseModal } = useModal();

  const profileQuery = useProfileQuery({ load });

  return {
    modalState,
    onCloseModal,
    onOpenModal,
    profileQuery,
  };
};
