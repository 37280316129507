import { useQuery } from "@tanstack/react-query";
import { GET_PROGRAM_DETAILS, getProgramDetails } from "..";

export const useProgramQuery = ({ load = false, studentBatchId = "" }) => {
  return useQuery({
    queryKey: [GET_PROGRAM_DETAILS, studentBatchId],
    queryFn: () => getProgramDetails(studentBatchId),
    enabled: !!studentBatchId && load,
  });
};
