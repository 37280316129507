import React from "react";
import { Modal, ModalBody } from "reactstrap";
import tokenIcon from "assets/images/assessment-session-expired.svg";

export const TokenExpiredModal = ({ message, isOpen, onSubmit, ...rest }) => {
  return (
    <Modal isOpen={isOpen} className="common-modal modal__timeout" {...rest}>
      <ModalBody>
        <div className="common-modal-content">
          <img src={tokenIcon} className="modal-icon" />
          <h3>Invalid Token</h3>
          <p>{message}</p>
          <div className="modal-action">
            <button onClick={onSubmit} className="btn btn-primary px-4">
              Ok
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
