import { Axios } from "utils";

export const getProgramCertificates = async (programId) => {
  let url = "/v2/portal/Programs/generated-certificates";
  if (programId) {
    url = url + `?programId=${programId}`;
  }
  const res = await Axios.get(url);
  return res.data;
};

export const getCertificateById = async (studentCertificateId) => {
  const res = await Axios.get(
    `/Certificates/CertrtificateViewHtml/${studentCertificateId}`
  );
  return res.data;
};

export const getStudentBatchCertificate = async (studentBatchId) => {
  const res = await Axios.get(
    `/v2/portal/Certificates/batches/${studentBatchId}`
  );
  return res.data;
};

export const getProgramSelectListForCertificate = async () => {
  const res = await Axios.get(`/v2/portal/Certificates/Programs/select-list`);
  return res.data;
};
