import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LandingSlideGraphics1 from "assets/images/landing-slide-graphics-1.svg";
import GstExecutiveGraphics from "assets/images/gst-executive-graphics.svg";
import { useDashboardBanner } from "../hooks";

export const DashboardBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const { getDashboardBannerData } = useDashboardBanner();
  const { data } = !!getDashboardBannerData && getDashboardBannerData;

  return (
    <div className="banner-slider slick-slider">
      <Slider {...settings}>
        {data &&
          data?.map((element, index) => (
            <img
              src={element}
              alt=""
              className="mw-100"
              key={`dashboard-banner-item-no-${index}`}
            />
          ))}
      </Slider>
    </div>
  );
};
