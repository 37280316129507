import React from "react";
import parse from "html-react-parser";
import { useFaq } from "..";
import { LoadingSpinner } from "shared";

export const FrequentlyQuestions = () => {
  const { settingsInfo } = useFaq({
    load: true,
    key: "page_FAQ",
  });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return <p>{data ? parse(data?.content) : ""}</p>;
};
