import React from "react";
import {
  CertificateItem,
  CertificatePreviewModal,
  useCertificate,
  useCertificateProgramSelectListQuery,
} from "..";
import { ErrorComponent, LoadingSpinner, PageLayout } from "shared";
import ErrorImg from "assets/images/error-no-certificate-found-graphics.svg";
import { useNavigate } from "react-router-dom";

export const CertificateList = ({ isAllCertificate }) => {
  const navigate = useNavigate();
  const data = localStorage?.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;
  const certificateProgramName = data?.name;
  const studentBatchId = data?.studentBatchId;
  const {
    selectedProgram,
    setSelectedProgram,
    downloadPdf,
    studentBatchCertificate,
    onCloseModal,
    modalState,
    onOpenModal,
  } = useCertificate({
    load: true,
    studentBatchId,
    isAllCertificate,
  });
  const { data: program } = useCertificateProgramSelectListQuery({
    load: true,
  });
  const hasCertificate = studentBatchCertificate?.data?.length > 0;

  const titleEndComponent = (
    <>
      {isAllCertificate && (
        <div className="select-dropdown-group">
          <select
            className="custom-select-drop"
            value={selectedProgram}
            onChange={(e) =>
              setSelectedProgram((draft) => {
                draft = e.target.value;
                return draft;
              })
            }
          >
            <option value="" selected>
              All Programs
            </option>
            {program &&
              program?.map((item) => (
                <option value={item.id} key={`program-${item.id}`}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      )}
    </>
  );

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  return (
    <PageLayout
      breadcrumb={
        !isAllCertificate
          ? [
              {
                label: `${
                  certificateProgramName ? certificateProgramName : ""
                }`,
                location: "/program",
              },
              { label: "Certificates" },
            ]
          : [{ label: "Certificates" }]
      }
      titleEndComponent={titleEndComponent}
      title={hasCertificate ? "Certificates" : ""}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-grid-items page-grid-items--box grid-item-certificate">
            {studentBatchCertificate?.isLoading ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {hasCertificate ? (
                  studentBatchCertificate?.data
                    ?.sort((a, b) => b?.isGenerated - a?.isGenerated)
                    ?.map((item) => (
                      <CertificateItem
                        item={item}
                        key={`ProgramName-${item.id}`}
                        onOpenModal={onOpenModal}
                        certificate={isAllCertificate}
                        certificateProgramName={certificateProgramName}
                      />
                    ))
                ) : (
                  <ErrorComponent
                    title="No Certificate Found!"
                    message="There is no certificate assigned for you at the moment."
                    image={ErrorImg}
                    btnLabel="Go back"
                    onClick={goToPreviousPage}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {modalState.isOpen && (
        <CertificatePreviewModal
          onCloseModal={onCloseModal}
          downloadPdf={downloadPdf}
          modalState={modalState}
        />
      )}
    </PageLayout>
  );
};
