import { Axios } from "utils";

export const getAssessmentList = async (programId) => {
  const res = await Axios.get(`/v2/portal/assessments/programs/${programId}`);
  return res.data;
};

export const getAssessmentSchedule = async ({
  assessmentId,
  programId,
  scheduleMasterId,
}) => {
  const res = await Axios.get(
    `/v2/portal/assessments/${assessmentId}/programs/${programId}/schedules/${scheduleMasterId}`
  );
  return res.data;
};

export const getAssessmentSlot = async (scheduleId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/schedules/${scheduleId}/slots`
  );
  return res.data;
};

export const createAssessmentSchedule = async ({ slotId }) => {
  const res = await Axios.post(
    `/v2/portal/assessments/${slotId}/create-schedule`
  );
  return res.data;
};

export const assessmentResult = async (assessmentMasterId) => {
  const res = await Axios.get(
    `/v2/portal/assessments/${assessmentMasterId}/result`,
    {
      headers: {
        "Exam-Token": sessionStorage.getItem("assessmentToken") || "",
      },
    }
  );
  return res.data;
};
