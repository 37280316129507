import React, { useEffect, useCallback, useState, forwardRef } from "react";
import { Input, FormFeedback } from "reactstrap";
import "../styles/controlledTextarea.style.css";

const ControlledTextarea = forwardRef((props, ref) => {
  const {
    value,
    id,
    limit = 200,
    name,
    label,
    onChange,
    isValidationRequired = false,
    ...rest
  } = props;

  const [content, setContent] = useState(value.slice(0, limit));

  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, limit));
    },
    [limit, setContent]
  );

  useEffect(() => {
    onChange({ value: content, name });
  }, [content]);

  const validate = isValidationRequired
    ? ref.current.message(name, content, "required")
    : false;

  return (
    <div className="controlled-textarea position-relative">
      <Input
        type="textarea"
        className="form-control"
        id={id}
        value={content}
        onChange={(e) => setFormattedContent(e.target.value)}
        invalid={validate}
        {...rest}
      />
      <p className="char-length">
        {content.length}/{limit}
      </p>
      {isValidationRequired && (
        <FormFeedback>
          {ref.current.message(name, content, "required")}
        </FormFeedback>
      )}
    </div>
  );
});

export default ControlledTextarea;
