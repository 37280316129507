import React from "react";
import Icon from "assets/images/icons/question-icon.svg";
import { useNavigate } from "react-router-dom";

export const HelpFlotingIcon = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/program/help");
  };

  return (
    <div className="floating-icon" onClick={onClick}>
      <img src={Icon} />
    </div>
  );
};
