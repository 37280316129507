import React, { useEffect, useState } from "react";
import {
  NotificationBanner,
  NotificationModal,
  useNotificationBanner,
} from "..";
import "../styles/notificationBanner.styles.css";
import { trimHtmlContent } from "utils";

export const NotificationHead = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const {
    notificationsBanner,
    closeNotification,
    setCloseNotification,
    dateFormatted,
    createViewedNotification,
  } = useNotificationBanner({ unreadOnly: true, load: true });
  const notificationsBannerLength =
    notificationsBanner?.data?.length > 2
      ? notificationsBanner?.data?.length - 2
      : 0;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const viewAllNotification = () => {
    let data = [];
    notificationsBanner?.data?.forEach((item) => {
      data.push(item.id);
    });
    createViewedNotification.mutate(data);
  };

  return (
    <>
      {notificationsBanner &&
      notificationsBanner?.data?.length > 0 &&
      closeNotification ? (
        <>
          {screenWidth > 768 ? (
            <div className="notification-slider">
              <div className="container">
                <div className="row">
                  <div className="col-12 notification-slider-wrap d-flex align-items-center justify-content-between">
                    <div className="notification-slider-group col-10 d-flex  justify-content-center pe-4">
                      {notificationsBanner &&
                        notificationsBanner?.data
                          ?.slice(0, 2)
                          ?.map((notification) => (
                            <NotificationBanner
                              key={`Notification${notification.id}`}
                              notification={notification}
                              dateFormatted={dateFormatted}
                              trimContent={trimHtmlContent}
                              viewAllNotification={viewAllNotification}
                            />
                          ))}
                    </div>
                    <div className="notification-slider-action col-2 d-flex align-items-center justify-content-end">
                      {notificationsBannerLength > 0 && (
                        <button
                          className="btn btn-primary"
                          onClick={viewAllNotification}
                          disabled={createViewedNotification.isPending}
                        >
                          View More ({notificationsBannerLength})
                        </button>
                      )}
                      <span
                        className="notifiction-close"
                        onClick={() =>
                          setCloseNotification((draft) => {
                            draft = false;
                            return draft;
                          })
                        }
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NotificationModal
              notificationsBanner={notificationsBanner}
              setCloseNotification={setCloseNotification}
              dateFormatted={dateFormatted}
              trimContent={trimHtmlContent}
              viewAllNotification={viewAllNotification}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
