import React from "react";
import { useQuestionareContext } from "master";

export const AssessmentTimer = () => {
  const { hours, minutes, seconds } = useQuestionareContext();

  const formatTimeDigit = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    } else {
      return "00";
    }
  };

  return (
    <h3
      className={`countdown-timer ${
        hours === 0 && minutes <= 1 ? "timeup-warning" : ""
      }`}
    >
      <span>{formatTimeDigit(hours)}</span>:
      <span>{formatTimeDigit(minutes)}</span>:
      <span>{formatTimeDigit(seconds)}</span>
    </h3>
  );
};
