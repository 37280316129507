import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Datetime from "react-datetime";
import moment from "moment";
import { TimeSelector } from "..";

export const AssessmentScheduleModal = ({
  isOpen,
  onCancel,
  setScheduleId,
  scheduleId,
  scheduleDateList,
  slots,
  isSlotLoading,
  setSelectedSlotId,
  selectedSlotId,
  onCreateAssessmentSchedule,
  setIsSlotInfoOpen,
  isSlotInfoOpen,
  ...rest
}) => {
  const onSelectAssessmentDate = (date) => {
    const selectedDate = moment(date).format("DD-MMM-YYYY");
    const selectedSchedule =
      scheduleDateList &&
      scheduleDateList?.find((s) => {
        return s?.scheduledDate === selectedDate;
      });

    setScheduleId((draft) => {
      draft = selectedSchedule?.scheduleId;
      return draft;
    });
  };

  const onSelectSlot = ({ slotId, scheduledDate, startAtStr, endAtStr }) => {
    setSelectedSlotId((draft) => {
      draft = slotId;
      return draft;
    });
    setIsSlotInfoOpen((draft) => {
      draft.data = {
        scheduledDate,
        startAtStr,
        endAtStr,
      };
    });
  };

  const yesterday = moment().subtract(1, "day");
  const validDates =
    scheduleDateList &&
    scheduleDateList?.map((date) => moment(date?.scheduledDate, "DD-MMM-YYYY"));

  const isValidDate = (current) => {
    return (
      validDates?.some((date) => date?.isSame(current, "day")) &&
      current?.isAfter(yesterday)
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      className="common-modal modal__assessment"
      fullscreen={"md"}
      scrollable={true}
      {...rest}
    >
      <ModalHeader toggle={onCancel}>Schedule Your Assessment</ModalHeader>
      <ModalBody>
        <div className="modal-content-block d-md-flex">
          <div className="modal-calendar-block col-12 col-md-5">
            <span className="block-title">Select a date for Assessment</span>
            <div className="pe-md-3">
              <Datetime
                input={false}
                timeFormat={false}
                isValidDate={isValidDate}
                onChange={onSelectAssessmentDate}
              />
            </div>
          </div>
          <div className="modal-time-block col-12 col-md-7">
            {!isSlotLoading ? (
              slots && slots?.slots?.length > 0 ? (
                <div>
                  <span className="block-title">Select a suitable time</span>
                  <div className="assessment-time-btns">
                    {slots?.slots?.map((slot) => {
                      return (
                        <TimeSelector
                          key={`slot-${slot?.slotId}`}
                          onSelectTime={() =>
                            onSelectSlot({
                              slotId: slot?.slotId,
                              scheduledDate: slots?.scheduledDate,
                              startAtStr: slot?.startAtStr,
                              endAtStr: slot?.endAtStr,
                            })
                          }
                          isEnabled={slot?.isEnabled}
                          isSelected={selectedSlotId === slot?.slotId}
                          startAtStr={slot?.startAtStr}
                          endAtStr={slot?.endAtStr}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <span>Please select a date to show available slots.</span>
                </div>
              )
            ) : (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <span>Loading...</span>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="assessment-modal-action-btns text-end">
          <button
            onClick={onCreateAssessmentSchedule}
            disabled={!selectedSlotId}
            className="btn btn-blue"
          >
            Schedule Assessment
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
