import { useImmer } from "use-immer";

export const useVideoPlayer = () => {
  const [modalState, setModalState] = useImmer({
    isModalOpen: false,
    url: "",
    title: "",
  });

  const toggleModal = () => {
    setModalState((draft) => {
      draft.isModalOpen = !draft.isModalOpen;
      return draft;
    });
  };

  const onSetUrl = (url, title) => {
    setModalState((draft) => {
      draft.isModalOpen = true;
      draft.url = url;
      draft.title = title;
      return draft;
    });
  };

  return {
    toggleModal,
    onSetUrl,
    modalState,
  };
};
