import React from "react";

export const CourseTaskDocs = ({
  index,
  taskContents,
  contentType,
  fileURL,
  setPdfFilePath,
}) => {
  let lastElemt = index + 1 === taskContents.length;
  if (contentType === 1) {
    var dirArray = fileURL.split("/");
    let dir = dirArray[dirArray.length - 2];
    let fileNames = dirArray[dirArray.length - 1];
    let filePath = `${dir}/${fileNames}`;
    setPdfFilePath(filePath);
    return (
      <div className={lastElemt ? "" : "mb-5"}>
        <iframe
          className="w-100"
          style={{ height: "100dvh" }}
          src={`/contents/${filePath}`}
        />
      </div>
    );
  } else {
    return (
      <div className={lastElemt ? "" : "mb-5"}>
        <iframe className="w-100" style={{ height: "100dvh" }} src={fileURL} />
      </div>
    );
  }
};
