import { useQuery } from "@tanstack/react-query";
import { GET_COURSE_TASK_LIST, getCourseTaskList } from "..";

export const useCourseTaskListQuery = ({
  load = false,
  programId = "",
  courseId = "",
}) => {
  return useQuery({
    queryKey: [GET_COURSE_TASK_LIST, programId, courseId],
    queryFn: () => getCourseTaskList(programId, courseId),
    enabled: !!programId && !!courseId && load,
    refetchOnWindowFocus: true,
  });
};
