import React from "react";

export const QuestionLegend = ({ type }) => {
  switch (type) {
    case "completed":
      return (
        <div className="legend-item">
          <span className="nav-bubble completed"></span>
          <span className="legend-txt">Completed</span>
        </div>
      );
    case "marked":
      return (
        <div className="legend-item">
          <span className="nav-bubble marked"></span>
          <span className="legend-txt">Marked</span>
        </div>
      );
    case "current":
      return (
        <div className="legend-item">
          <span className="nav-bubble current"></span>
          <span className="legend-txt">Current</span>
        </div>
      );
    case "toAttend":
      return (
        <div className="legend-item">
          <span className="nav-bubble not-attended"></span>
          <span className="legend-txt">Yet to attend</span>
        </div>
      );
    default:
      return null;
  }
};
