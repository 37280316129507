import React from "react";
import appInfo from "../../../package.json";
import { FooterLink } from "./FooterLink";
export const MainFooter = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row">
          <div className="main-footer-block d-flex align-items-center justify-content-between">
            <span className="app-version">{`v${appInfo.version}`}</span>
            <nav className="footer-nav">
              <ul>
                <FooterLink to={"/privacy"} label={"Privacy"} />
                <FooterLink to={"/terms"} label={"Terms"} />
                <FooterLink to={"/faq"} label={"FAQ"} />
                {/* <FooterLink to={"/refund-policy"} label={"Refund Policy"} />
                <FooterLink to={"/contact-us"} label={"Contact Us"} /> */}
              </ul>
            </nav>
            <span className="copyright-text">©NERGY INDIA PVT LTD</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
