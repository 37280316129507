import React from "react";
import { PageHeader } from "..";
import "../styles/pageLayout.style.css";

export const PageLayout = ({
  children,
  breadcrumb = [],
  titleEndComponent = null,
  title = "",
  isScrollable = true,
  isBreadCrumbsMarginRequired = true,
  isFullWidthContainer = true,
  isRowRequired = true,
  isBreadCrumbsRequired = true,
  isPageHeaderRequired = true,
  isBreadCrumbsPaddingRequired = true,
}) => {
  return (
    <>
      <section
        className={`main-page-wrapper ${
          isBreadCrumbsPaddingRequired ? "" : "pt-0"
        }`}
      >
        {isPageHeaderRequired && (
          <div className="container">
            <div className="row">
              <PageHeader
                breadcrumb={breadcrumb}
                titleEndComponent={titleEndComponent}
                title={title}
                isBreadCrumbsMarginRequired={isBreadCrumbsMarginRequired}
                isBreadCrumbsRequired={isBreadCrumbsRequired}
              />
            </div>
          </div>
        )}
        <div
          className={
            isFullWidthContainer ? "container" : "container-fluid px-0"
          }
        >
          <div className={isRowRequired ? "row" : ""}>
            <div className={isScrollable ? "layout-scrollable" : ""}>
              {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
