import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorComponent, LoadingSpinner, PageLayout } from "shared";
import {
  CourseTaskSideBar,
  CourseTheory,
  CouseTaskView,
  useCourseTask,
} from "..";
import { trimText } from "utils";
import ErrorImg from "assets/images/error-theory-not-found-graphics.svg";

export const CourseTask = ({ type }) => {
  let { courseId } = useParams();
  const selectedProgram = localStorage.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;
  const navigate = useNavigate();
  const {
    name,
    studentTasks,
    studentTheory,
    courseTaskList,
    hasCourseGroup,
    displayName,
    selectedProgramName,
    courseGroupName,
    currentCourseTask,
    onCourseTaskCardClick,
    courseTaskDetails,
    courseType,
    updateCourseTasksStatus,
    onStartSimulation,
    setCurrentCourseTask,
    studentDataDisplayName,
    modalState,
    onOpenModal,
    onCloseModal,
    studentSpecificDataQuery,
    currentCourseTaskIndex,
    setCurrentCourseTaskIndex,
    onPrevTask,
    onNextTask,
    studentTheoryDetails,
  } = useCourseTask({ load: true, courseId });

  const isAssignment = type === 2;
  const isPractical = type === 1;
  const isTheory = type === 0;
  const currentName = isAssignment
    ? "Assignment"
    : isTheory
    ? "Learning Materials"
    : "Practicals";

  const hasCourseTask = studentTasks?.length > 0;
  const hasCourseTheory = studentTheory?.length > 0;
  const isLastTask = studentTasks?.length - 1 === currentCourseTaskIndex;
  const { data: taskDetails, isPending } = courseTaskDetails;
  const { title, taskGroup, taskContents, taskSections, description } =
    !!taskDetails && taskDetails;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (hasCourseTask && currentCourseTask === null) ||
      (hasCourseTask &&
        !studentTasks?.some(
          (e) => e?.courseTaskId === currentCourseTask?.courseTaskId
        ))
    ) {
      onCourseTaskCardClick(studentTasks[0]);
      setCurrentCourseTaskIndex(0);
      sessionStorage.setItem("currentTask", JSON.stringify(studentTasks[0]));
    } else {
      const newCurrent = studentTasks?.find(
        (e) => e?.courseTaskId === currentCourseTask?.courseTaskId
      );
      const newCurrentIndex = studentTasks?.findIndex(
        (e) => e?.courseTaskId === currentCourseTask?.courseTaskId
      );
      if (newCurrent) {
        setCurrentCourseTask(newCurrent);
        setCurrentCourseTaskIndex(newCurrentIndex);
      }
    }
  }, [studentTasks]);

  const onCourseCardNavigate = (element) => {
    onCourseTaskCardClick(element);
    navigate(`view`);
  };

  if (courseTaskList?.isPending || courseTaskList?.isLoading) {
    return <LoadingSpinner />;
  }
  const breadcrumb = [
    { label: selectedProgramName, location: "/program" },
    {
      label: displayName ? displayName : currentName,
      location: isAssignment
        ? `/program/assignment/${
            hasCourseGroup ? "coursegroup" : "courseslist"
          }`
        : isTheory
        ? `/program/learningmaterials/${
            hasCourseGroup ? "coursegroup" : "courseslist"
          }`
        : `/program/practicals/${
            hasCourseGroup ? "coursegroup" : "courseslist"
          }`,
    },
    {
      label: hasCourseGroup ? courseGroupName : trimText(name),
      location: hasCourseGroup
        ? isAssignment
          ? "/program/assignment/courseslist"
          : isTheory
          ? "/program/learningmaterials/courseslist"
          : "/program/practicals/courseslist"
        : "",
    },
  ];

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  if (hasCourseGroup) {
    breadcrumb.push({ label: name });
  }

  return (
    <PageLayout
      isBreadCrumbsMarginRequired={false}
      isFullWidthContainer={false}
      isRowRequired={false}
      breadcrumb={breadcrumb}
      isBreadCrumbsPaddingRequired={screenWidth > 767}
    >
      <div className="single-page-content">
        {hasCourseTheory || hasCourseTask ? (
          <div className="container">
            <div className="row">
              {(isPractical || isAssignment) && hasCourseTask && (
                <>
                  <CourseTaskSideBar
                    name={name}
                    studentTasks={studentTasks}
                    currentCourseTask={currentCourseTask}
                    onCourseTaskCardClick={onCourseTaskCardClick}
                    onCourseCardNavigate={onCourseCardNavigate}
                    isMobile={screenWidth < 768}
                    studentDataDisplayName={studentDataDisplayName}
                    modalState={modalState}
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    studentSpecificDataQuery={studentSpecificDataQuery}
                    courseType={courseType}
                    displayDuration={selectedProgram?.displayDuration}
                  />
                  {screenWidth > 767 && (
                    <CouseTaskView
                      title={title}
                      isPending={isPending}
                      description={description}
                      taskGroup={taskGroup}
                      taskContents={taskContents}
                      taskSections={taskSections}
                      courseType={courseType}
                      currentCourseTask={currentCourseTask}
                      onStartSimulation={onStartSimulation}
                      updateCourseTasksStatus={updateCourseTasksStatus}
                      isMobile={screenWidth < 768}
                      currentCourseTaskIndex={currentCourseTaskIndex}
                      onCourseTaskCardClick={onCourseTaskCardClick}
                      onPrevTask={onPrevTask}
                      onNextTask={onNextTask}
                      isLastTask={isLastTask}
                    />
                  )}
                </>
              )}
              {isTheory && hasCourseTheory && (
                <CourseTheory
                  studentTheory={studentTheory}
                  studentTheoryDetails={studentTheoryDetails}
                  modalState={modalState}
                  onOpenModal={onOpenModal}
                  onCloseModal={onCloseModal}
                  studentSpecificDataQuery={studentSpecificDataQuery}
                />
              )}
            </div>
          </div>
        ) : (
          <ErrorComponent
            title={`No Tasks Found!`}
            message={`There is no Tasks assigned for you at the moment.`}
            image={ErrorImg}
            btnLabel="Go back"
            onClick={goToPreviousPage}
          />
        )}
      </div>
    </PageLayout>
  );
};
