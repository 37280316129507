import parse from "html-react-parser";
import { errorMessage } from ".";

export const errorFormatter = (error) => {
  let errorString = "";

  if (error.response) {
    if (error.response.data.message && !error.response.data.errors) {
      errorString = error.response.data.message;
    }
    if (error.response.data.errors !== null) {
      for (var err in error.response.data.errors) {
        errorString += error.response.data.errors[err];
      }
    } else if (!error.response.data.message) {
      errorString = "An error has occured!";
    }
  } else {
    errorString = "An error has occured!";
  }
  errorMessage(parse(`${errorString}`));
};
