import React from "react";
import {
  AssessmentList,
  AssessmentResult,
  ExpiredPage,
  Questionnaire,
  QuestionsAllMobile,
  Review,
} from "..";
import { Routes, Route } from "react-router-dom";
import "./styles/assessment.styles.css";
import "./styles/assessmentQuestions.styles.css";
import "./styles/assessmentReview.styles.css";
import "./styles/assessmentResult.styles.css";

export const Assessment = () => {
  return (
    <Routes>
      <Route path="/" element={<AssessmentList />} />
      <Route path="/start" element={<Questionnaire />} />
      <Route path="/review" element={<Review />} />
      <Route path="/expired" element={<ExpiredPage />} />
      <Route path="/allQuestions" element={<QuestionsAllMobile />} />
      <Route path="/result" element={<AssessmentResult />} />
    </Routes>
  );
};
