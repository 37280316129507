import { useNavigate } from "react-router-dom";
import { ErrorComponent, PageLayout } from "shared";
import ErrorImg from "assets/images/error-page-not-found-graphics.svg";

export const PageNotFound = () => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  return (
    <PageLayout>
      <ErrorComponent
        title="404 - Page Not Found!"
        message="The requested URL was not found on this server."
        image={ErrorImg}
        btnLabel="Go back"
        onClick={goToPreviousPage}
      />
    </PageLayout>
  );
};
