import React from "react";
import parse from "html-react-parser";

export const ReviewItem = ({
  question,
  index,
  markedQuestions,
  onQuestionClick,
  isTimeout,
}) => {
  const { description, questionId, sectionId, answer, choices } = question;

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c?.questionId === questionId && c?.sectionId === sectionId
    );

  const isAnswered =
    answer != null && choices?.some((c) => c?.choiceId === answer?.choiceId);

  const onQuestionClickHandle = () => {
    if (!isTimeout) {
      onQuestionClick(sectionId, questionId);
    }
  };

  const itemMarked = isMarked || (isAnswered && isMarked);
  const itemAnswered = isAnswered && !isMarked;
  const itemNotAnswered = !isAnswered && !isMarked;

  return (
    <li className="review-item">
      <div className="review-content-col">
        <div className="review-idx-col">
          <span>{index + 1}</span>
        </div>
        <div>{!!description && parse(description)}</div>
      </div>
      <div className="review-action-col">
        <button
          disabled={isTimeout}
          className={`review-ite-nav-link ${
            itemMarked ? "mark-for-review" : ""
          } ${itemAnswered ? "completed" : ""} ${
            itemNotAnswered ? "review-not-answered" : ""
          }`}
          onClick={onQuestionClickHandle}
        >
          {itemAnswered && <span>Answered</span>}
          {itemMarked && <span>Marked for review</span>}
          {itemNotAnswered && <span>Not Answered</span>}
          {!isTimeout && (
            <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none">
              <path
                d="M10 7L15 12L10 17"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
    </li>
  );
};
