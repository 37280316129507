import React from "react";
import { useQuery } from "@tanstack/react-query";
import { GET_BANNER_FOR_DASHBOARD, getDashboardBanner } from "..";

export const useDashboardBanner = () => {
  const isMobile = window.innerWidth < 768;

  const getDashboardBannerData = useQuery({
    queryKey: [GET_BANNER_FOR_DASHBOARD],
    queryFn: () => getDashboardBanner(isMobile),
  });

  return {
    getDashboardBannerData,
  };
};
