export const QuestionStatusBadge = ({
  question,
  index,
  markedQuestions,
  currentQuestionIndex,
  onSelect,
}) => {
  const { questionId, sectionId, answer, choices, questionNumber } = question;

  const isCurrentQuestion =
    currentQuestionIndex && currentQuestionIndex?.questionIndex === index;

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c.questionId === questionId && c.sectionId === sectionId
    );

  const isAnswered =
    answer != null && choices?.some((c) => c.choiceId === answer.choiceId);

  return (
    <button
      onClick={onSelect ? onSelect : null}
      className={`question-nav-btn ${isCurrentQuestion ? "current" : ""}  ${
        isAnswered ? "completed" : ""
      }`}
    >
      <span>{questionNumber}</span>
      {(isMarked || isAnswered) && (
        <span
          className={`nav-bubble ${isMarked ? "marked" : ""} ${
            isAnswered ? "completed" : ""
          }`}
        ></span>
      )}
    </button>
  );
};
