import { useImmer } from "use-immer";
import { useStudentBatchCertificateQuery } from ".";
import { trimText } from "utils";
import { useModal } from "shared";

export const useCertificate = ({
  load = false,
  studentBatchId,
  isAllCertificate,
}) => {
  const [selectedProgram, setSelectedProgram] = useImmer("");

  const { modalState, onOpenModal, onCloseModal } = useModal();
  const studentBatchCertificate = useStudentBatchCertificateQuery({
    load: load,
    studentBatchId,
    programId: selectedProgram,
    isAllCertificate,
  });

  const downloadPdf = async (url = "", certificateName) => {
    if (url) {
      let c_name = trimText(certificateName, 30, "");
      const fileName = c_name.split(" ").join("_");
      await fetch(url).then((response) => {
        response.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${fileName}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
      });
    }
  };

  return {
    downloadPdf,
    studentBatchCertificate,
    selectedProgram,
    setSelectedProgram,
    modalState,
    onOpenModal,
    onCloseModal,
  };
};
