import React from "react";
import { MainHeader, PageLayout } from "shared";
import { FrequentlyQuestions } from ".";

export const Faq = () => {
  return (
    <>
      <MainHeader />
      <PageLayout
        title="Frequently Asked Questions"
        breadcrumb={[{ label: "FAQ" }]}
      >
        <FrequentlyQuestions />
      </PageLayout>
    </>
  );
};
