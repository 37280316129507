import React from "react";
import { Route, Routes } from "react-router-dom";
import { LandingPage } from ".";

export const DashboardView = () => {
  return (
    <Routes>
      <Route element={<LandingPage />} path={"/"} />
    </Routes>
  );
};
