import { useCallback } from "react";
import { useImmer } from "use-immer";

export const useMediaPreview = () => {
  const [modalState, setModalState] = useImmer({
    isModalOpen: false,
    url: "",
    title: "",
    mediaType: "",
  });

  const toggleModal = useCallback(() => {
    setModalState((draft) => {
      draft.isModalOpen = !draft.isModalOpen;
      return draft;
    });
  }, [modalState]);

  const onSetUrl = ({ url = "", title = "", mediaType = null }) => {
    setModalState((draft) => {
      draft.isModalOpen = true;
      draft.url = url;
      draft.title = title;
      draft.mediaType = mediaType;
      return draft;
    });
  };

  return {
    toggleModal,
    onSetUrl,
    modalState,
  };
};
