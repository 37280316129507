import React from "react";
import { Link } from "react-router-dom";

export const FooterLink = ({ to = "", label = "", target = false }) => {
  return (
    <li>
      <Link to={to} target={target ? "_self" : "_blank"}>
        {label}
      </Link>
    </li>
  );
};
