import React from "react";
import { useNavigate } from "react-router-dom";
import { CommonIcons } from "shared";

import FinanceExecutiveGraphics from "assets/images/default-program-thumbnail.jpg";
import ArrowRightIconWhite from "assets/images/icons/arrow-right-icon-white.svg";

export const ProgramCard = ({
  data,
  onOpenModal,
  setCurrentProgram,
  activateBatchMutation,
}) => {
  const navigate = useNavigate();
  const {
    id,
    studentBatchId,
    name,
    terms,
    illustrationLogoUrl,
    hasLearningMaterials,
    hasAssessment,
    hasAssignment,
    hasCertificates,
    isAssessmentCompleted,
    isAssignmentCompleted,
    isCertificateCompleted,
    isLearningMaterialsCompleted,
    isActivated,
    requireRegIdVerify,
    isTermAccepted,
    associateName,
    displayDuration,
  } = data;

  const onButtonClick = () => {
    setCurrentProgram((draft) => {
      draft.id = id;
      draft.studentBatchId = studentBatchId;
      draft.name = name;
      draft.displayDuration = displayDuration;
      return draft;
    });
    if (isActivated && (isTermAccepted || !terms)) {
      navigate(`/program`);
      localStorage.setItem(
        "selectedProgram",
        JSON.stringify({ id, studentBatchId, name, displayDuration })
      );
    } else if (!isActivated && requireRegIdVerify) {
      onOpenModal({
        actionFrom: "confirmRegistration",
        info: studentBatchId,
      });
    } else if (!isActivated && !requireRegIdVerify) {
      activateBatchMutation.mutate(
        {
          studentBatchId,
          regNo: null,
        },
        {
          onSuccess: () => {
            if (!isTermAccepted && !!terms) {
              onOpenModal({
                actionFrom: "acceptTerms",
                info: { studentBatchId, terms },
              });
            } else {
              navigate(`/program`);
              localStorage.setItem(
                "selectedProgram",
                JSON.stringify({ id, studentBatchId, name, displayDuration })
              );
            }
          },
        }
      );
    } else if (!isTermAccepted && !!terms) {
      onOpenModal({
        actionFrom: "acceptTerms",
        info: { studentBatchId, terms },
      });
    }
  };

  return (
    <div className="featured-item">
      <div className="item-thumbnail" style={{ backgroundColor: "#fff" }}>
        <img
          className="img-fluid"
          src={
            illustrationLogoUrl ? illustrationLogoUrl : FinanceExecutiveGraphics
          }
          alt=""
        />
      </div>
      <div className="featured-content">
        <h3>{name}</h3>
        <div className="featured-badge">
          {associateName && <span className="badge">{associateName}</span>}
        </div>
        <div className="featured-footer">
          <div className="indicators-list">
            {hasLearningMaterials && (
              <CommonIcons
                type="learning"
                isActive={isLearningMaterialsCompleted}
              />
            )}
            {hasAssignment && (
              <CommonIcons
                type="asssignment"
                isActive={isAssignmentCompleted}
              />
            )}
            {hasAssessment && (
              <CommonIcons type="assessment" isActive={isAssessmentCompleted} />
            )}
            {hasCertificates && (
              <CommonIcons
                type="cretificate"
                isActive={isCertificateCompleted}
              />
            )}
          </div>
          <button
            className="btn btn-primary"
            onClick={onButtonClick}
            disabled={
              activateBatchMutation?.isPending &&
              activateBatchMutation?.variables?.studentBatchId ===
                studentBatchId
            }
          >
            <span className="btn-txt">Start learning</span>
            <span className="btn-arrow">
              <img src={ArrowRightIconWhite} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
