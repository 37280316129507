import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  DashboardView,
  Program,
  Notifications,
  NotificationHead,
  Certificate,
} from "modules";
import { PageNotFound } from ".";
import {
  MainFooter,
  MainHeader,
  GlobalErrorHandler,
  HeaderRightBlock,
} from "shared";
import { QuestionnaireScope } from "master";

export const Home = () => {
  const location = useLocation();

  const isQuestionnaire =
    location.pathname === "/program/assessment/start" ||
    location.pathname === "/program/assessment/review" ||
    location.pathname === "/program/assessment/result" ||
    location.pathname === "/program/assessment/allQuestions";

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <div>
      <div id="main" className="layout-navbar">
        <div id="main-content">
          <QuestionnaireScope>
            <GlobalErrorHandler>
              {location.pathname == "/" && <NotificationHead />}
              <MainHeader>
                <HeaderRightBlock />
              </MainHeader>
              <Routes>
                <Route element={<DashboardView />} path={"/"} />
                <Route element={<Program />} path="/program/*" />
                <Route element={<PageNotFound />} path={"/*"} />
                <Route element={<Notifications />} path={"/notifications"} />
                <Route
                  element={<Certificate isAllCertificate={true} />}
                  path={"/certificate/*"}
                />
              </Routes>
              {!isQuestionnaire && <MainFooter />}
            </GlobalErrorHandler>
          </QuestionnaireScope>
        </div>
      </div>
    </div>
  );
};
