import React from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/help.styles.css";
import { HelpView } from ".";

export const Help = () => {
  return (
    <Routes>
      <Route element={<HelpView />} path={"/"} />
    </Routes>
  );
};
