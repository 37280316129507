import React from "react";
import "../styles/errorPage.style.css";

export const ErrorComponent = ({
  title,
  message,
  image,
  btnLabel,
  onClick,
}) => {
  return (
    <div className="error-page-content">
      <div className="error-content-block">
        <img src={image} />
        <h2 className="error-title">{title}</h2>
        <p className="error-description">{message}</p>
        {onClick && (
          <div className="error-page-action">
            <button className="btn btn-blue" onClick={onClick}>
              {btnLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
