import { useQuery } from "@tanstack/react-query";
import { GET_PROGRAM_CERTIFICATE_BY_ID, getCertificateById } from "..";

export const useProgramCertificate = (studentCertificateId = "") => {
  return useQuery({
    queryKey: [GET_PROGRAM_CERTIFICATE_BY_ID, studentCertificateId],
    queryFn: () => getCertificateById(studentCertificateId),
    enabled: !!studentCertificateId,
  });
};
