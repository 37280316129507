import React, { useEffect } from "react";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import { LoadingSpinner } from "shared";
import leftArrowIcon from "assets/images/icons/left-arrow-long.svg";
import linkedin from "assets/images/icons/linkedin-icon.svg";
import download from "assets/images/icons/download-icon-white.svg";
import { useProgramCertificate } from "..";

export const CertificatePreviewModal = ({
  downloadPdf,
  modalState,
  onCloseModal,
}) => {
  const {
    info: {
      studentCertificateId,
      certificateUrl,
      title,
      programName,
      certificateName,
      certificateDate,
      certificateGuid,
    },
  } = modalState;

  const getCertificateUrl = useProgramCertificate(
    certificateUrl ? "" : studentCertificateId
  );
  const { data: url, isLoading } = !!getCertificateUrl && getCertificateUrl;
  const generatedCertificateUrl = certificateUrl ? certificateUrl : url;

  const date = moment(certificateDate, "DD-MMM-YYYY hh:mm:ss A");
  const month = date.format("MM");
  const year = date.format("YYYY");
  const organizationId = "31202632";
  const certificateVerfyUrl = `https://verify.nergy360.com/certificate/${certificateGuid}`;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  const loadingPDF = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "70vh", color: "white" }}
    >
      Loading PDF…
    </div>
  );
  return (
    <div className="preview-modal">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="preview-header">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="preview-header-block d-flex align-items-center justify-content-between">
                    <div className="preview-head">
                      <button
                        className="preview-back-btn"
                        onClick={onCloseModal}
                      >
                        <img src={leftArrowIcon} />
                      </button>
                      <h2>{programName}</h2>
                    </div>
                    <div className="preview-actions">
                      <a
                        href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certificateName}&organizationId=${organizationId}&issueMonth=${month}&issueYear=${year}&certUrl=${certificateVerfyUrl}`}
                        target="_blank"
                        className="btn btn-secondary"
                      >
                        <span>Share to</span>
                        <img src={linkedin} />
                      </a>
                      <button
                        type="button"
                        className="btn btn-primary certificate-download-btn"
                        onClick={() =>
                          downloadPdf(generatedCertificateUrl, title)
                        }
                      >
                        <span>Download</span>
                        <img src={download} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="preview-content">
            <div className="preview-overlay"></div>
            <div className="preview-box">
              <div className="d-flex justify-content-center align-items-center w-100">
                {!isLoading && (
                  <>
                    {generatedCertificateUrl ? (
                      <>
                        <Document
                          file={generatedCertificateUrl}
                          className="d-flex justify-content-center"
                          loading={loadingPDF}
                        >
                          <Page
                            pageNumber={1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                      </>
                    ) : (
                      <span
                        className="d-flex justify-content-center align-items-center text-white"
                        style={{ height: "100%" }}
                      >
                        Certificate not Found!
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
