import React from "react";
import { Spinner } from "reactstrap";

export const CourseTaskFooter = ({
  courseType,
  taskGroup,
  isPending,
  updateCourseTasksStatus,
  onStartSimulation,
  currentCourseTask,
  isMobile,
  currentCourseTaskIndex,
  onPrevTask,
  onNextTask,
  isLastTask,
}) => {
  const { id, studentTaskStatus } = !!currentCourseTask && currentCourseTask;
  const isOjt = courseType === 2;
  const isSimulation = taskGroup === 0;
  const completed = studentTaskStatus === 2;
  const isOjtCompleted = isOjt && completed;
  const isCourseTaskComlpeteExeptSimulation = completed && !isSimulation;
  const isFirstTask = currentCourseTaskIndex === 0;

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return isSimulation ? "Start" : "Mark as complete";
      case 1:
        return isSimulation ? "Continue" : "Mark as complete";
      case 2:
        return isOjt ? (
          <span>
            Completed{" "}
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </span>
        ) : !isSimulation ? (
          <span>
            Completed{" "}
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </span>
        ) : (
          "Re-Do Task"
        );
      default:
        return "";
    }
  };

  const updateCourseTaskStatusToComplete = () => {
    updateCourseTasksStatus.mutate({ studentTaskId: id, taskStatus: 2 });
  };

  const isButtonDisabled = updateCourseTasksStatus?.isPending;

  return (
    <div
      className={
        isMobile ? "content-footer" : "content-footer justify-content-between"
      }
    >
      {isPending ? (
        <></>
      ) : (
        <>
          {!isMobile && (
            <div className="d-flex align-items-center gap-4">
              <button
                onClick={onPrevTask}
                className="task-navigate-btn"
                disabled={isFirstTask}
              >
                <svg width="18" height="18" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.8465 1.99518C10.946 1.9967 11.0427 2.02787 11.1244 2.08471C11.2061 2.14155 11.269 2.22147 11.305 2.31425C11.341 2.40702 11.3485 2.50843 11.3265 2.60549C11.3046 2.70256 11.2542 2.79086 11.1817 2.85911L5.88487 8.00038L11.1817 13.1417C11.2299 13.1871 11.2686 13.2417 11.2955 13.3023C11.3224 13.3629 11.337 13.4282 11.3384 13.4944C11.3398 13.5606 11.3281 13.6265 11.3039 13.6882C11.2796 13.7498 11.2434 13.8061 11.1972 13.8536C11.1511 13.9011 11.0959 13.939 11.035 13.9651C10.9741 13.9911 10.9086 14.0048 10.8423 14.0053C10.7761 14.0058 10.7104 13.9932 10.6491 13.9681C10.5877 13.943 10.532 13.9059 10.4851 13.8591L4.81846 8.35911C4.77047 8.3125 4.73232 8.25674 4.70626 8.19512C4.68021 8.1335 4.66679 8.06728 4.66679 8.00038C4.66679 7.93348 4.68021 7.86727 4.70626 7.80565C4.73232 7.74403 4.77047 7.68827 4.81846 7.64166L10.4851 2.14166C10.5325 2.09429 10.5889 2.05691 10.6509 2.03175C10.713 2.00659 10.7795 1.99415 10.8465 1.99518Z"
                    fill="#000000"
                  />
                </svg>
                <span>Previous</span>
              </button>
              <button
                onClick={onNextTask}
                className="task-navigate-btn"
                disabled={isLastTask}
              >
                <span>Next</span>
                <svg width="18" height="18" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M5.15355 1.99518C5.05404 1.9967 4.95725 2.02787 4.87557 2.08471C4.79388 2.14155 4.73101 2.22147 4.69501 2.31425C4.659 2.40702 4.6515 2.50843 4.67346 2.60549C4.69541 2.70256 4.74583 2.79086 4.81826 2.85911L10.1151 8.00038L4.81826 13.1417C4.77008 13.1871 4.73142 13.2417 4.70453 13.3023C4.67764 13.3629 4.66305 13.4282 4.66161 13.4944C4.66017 13.5606 4.67191 13.6265 4.69614 13.6882C4.72038 13.7498 4.75662 13.8061 4.80278 13.8536C4.84894 13.9011 4.90408 13.939 4.965 13.9651C5.02593 13.9911 5.09142 14.0048 5.15768 14.0053C5.22393 14.0058 5.28963 13.9932 5.35095 13.9681C5.41227 13.943 5.46799 13.9059 5.51487 13.8591L11.1815 8.35911C11.2295 8.3125 11.2677 8.25674 11.2937 8.19512C11.3198 8.1335 11.3332 8.06728 11.3332 8.00038C11.3332 7.93348 11.3198 7.86727 11.2937 7.80565C11.2677 7.74403 11.2295 7.68827 11.1815 7.64166L5.51487 2.14166C5.46752 2.09429 5.41114 2.05691 5.34907 2.03175C5.28701 2.00659 5.22051 1.99415 5.15355 1.99518Z"
                    fill="#000000"
                  />
                </svg>
              </button>
            </div>
          )}
          {!isOjtCompleted && !isCourseTaskComlpeteExeptSimulation && (
            <button
              className="btn btn-primary footer-rounded-btn"
              onClick={
                isSimulation
                  ? onStartSimulation
                  : updateCourseTaskStatusToComplete
              }
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? (
                <Spinner color="light" size="sm">
                  Loading...
                </Spinner>
              ) : (
                getStatus(studentTaskStatus)
              )}
            </button>
          )}
          {isOjtCompleted && (
            <button
              className="btn btn-primary footer-rounded-btn"
              disabled={isButtonDisabled}
            >
              <span className="completed-icon"></span>
              <span>
                {isButtonDisabled ? (
                  <Spinner color="light" size="sm">
                    Loading...
                  </Spinner>
                ) : (
                  getStatus(studentTaskStatus)
                )}
              </span>
            </button>
          )}
          {isCourseTaskComlpeteExeptSimulation && (
            <button className="btn btn-primary footer-rounded-btn">
              {getStatus(studentTaskStatus)}
            </button>
          )}
        </>
      )}
    </div>
  );
};
