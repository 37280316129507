import React from "react";
import speakerIconBrown from "assets/images/icons/speaker-icon-brown.svg";
import { CommonIcons, CommonProgressBar } from "shared";
import { formatDuration } from "utils";

export const ProgramDetailsCard = ({
  title,
  theme,
  progress = null,
  totalAssignmentHour = null,
  completedAssignmentHour = null,
  totalCount = null,
  completedCount = null,
  scheduledAssessmentCount = null,
  isAssessment = false,
  displayDuration = false,
  icon,
  onClick,
  assignmentProgress,
}) => {
  const progressBar = parseInt(assignmentProgress).toFixed();

  const generateIndicators = (totalCount, completedCount) => {
    const indicators = [];

    for (let i = 0; i < totalCount; i++) {
      indicators.push(
        <>
          {isAssessment ? (
            <CommonIcons type="assessment" isActive={i + 1 <= completedCount} />
          ) : (
            <CommonIcons
              type="cretificate"
              isActive={i + 1 <= completedCount}
            />
          )}
        </>
      );
    }
    return indicators;
  };

  const handleDuration = () => {
    if (displayDuration) {
      return (
        formatDuration(completedAssignmentHour) +
        "/" +
        formatDuration(totalAssignmentHour)
      );
    } else {
      return null;
    }
  };

  return (
    <div className="grid-item" onClick={onClick}>
      <div className="grid-item-header">
        <div className="grid-icon" style={{ backgroundColor: theme }}>
          <img src={icon} />
        </div>
        {isAssessment && !!totalCount && (
          <div className="grid-badge">
            <img src={speakerIconBrown} />
            <span>
              {scheduledAssessmentCount + "/" + totalCount} Assessment scheduled
            </span>
          </div>
        )}
      </div>
      <div className="grid-item-content">
        <h2>{title}</h2>
        {progress !== null && (
          <CommonProgressBar width={progress} label={progress + "%"} />
        )}
        {(totalAssignmentHour !== null ||
          completedAssignmentHour !== null ||
          !!assignmentProgress) && (
          <CommonProgressBar
            width={!!progressBar ? progressBar : 0}
            label={displayDuration ? handleDuration() : progressBar + "%"}
          />
        )}

        {(totalCount !== null || completedCount !== null) && (
          <div className="indicators-list">
            {generateIndicators(totalCount, completedCount)}
          </div>
        )}
      </div>
    </div>
  );
};
