import React from "react";

export const TimeSelector = ({
  isEnabled,
  isSelected,
  startAtStr,
  endAtStr,
  onSelectTime,
}) => {
  return (
    <button
      className={`time-btn ${isSelected ? "active" : ""}`}
      disabled={!isEnabled}
      onClick={onSelectTime}
    >
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
          d="M8.90723 0C4.49842 0 0.907227 3.5912 0.907227 8C0.907227 12.4088 4.49842 16 8.90723 16C13.316 16 16.9072 12.4088 16.9072 8C16.9072 3.5912 13.316 0 8.90723 0ZM8.90723 1.6C12.4513 1.6 15.3072 4.4559 15.3072 8C15.3072 11.5441 12.4513 14.4 8.90723 14.4C5.36313 14.4 2.50723 11.5441 2.50723 8C2.50723 4.4559 5.36313 1.6 8.90723 1.6ZM8.10723 3.2V8.33125L11.5416 11.7656L12.6729 10.6344L9.70723 7.66875V3.2H8.10723Z"
          fill="#4D4D4D"
        />
      </svg>
      <span>{`${startAtStr} - ${endAtStr}`}</span>
    </button>
  );
};
