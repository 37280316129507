import moment from "moment";

export const ScheduledDateView = ({ scheduleDate, startTime, endTime }) => {
  const day = moment(scheduleDate)?.format("DD");
  const yearMonth = moment(scheduleDate)?.format("MMM-YYYY");
  const startAt = moment(startTime)?.format("hh:mm A");
  const endAt = moment(endTime)?.format("hh:mm A");

  return (
    <div className="schedule-date-box">
      <span className="date-box-label">Assessment Scheduled</span>
      <div className="date-group-box">
        <div className="date-time-box date-box">
          <span className="span-day">{day}</span>
          <span className="span-month-year">{yearMonth}</span>
        </div>
        <div className="date-time-box date-box">
          <span className="span-time">{startAt}</span>
          <span className="span-time-sm-txt">to</span>
          <span className="span-time">{endAt}</span>
        </div>
      </div>
    </div>
  );
};
