import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useImmer } from "use-immer";

export const useTimeout = ({ currentTime, startTime }) => {
  const intervalRef = useRef();

  const calculateTimeDifference = (start, current) => {
    const startAt = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const currTime = moment(current).format("YYYY-MM-DDTHH:mm:ss");
    return new Date(startAt) - new Date(currTime);
  };

  const [timer, setTimer] = useImmer(() =>
    calculateTimeDifference(startTime, currentTime)
  );

  useEffect(() => {
    clearInterval(intervalRef.current);

    const newTimer = calculateTimeDifference(startTime, currentTime);
    setTimer(newTimer);

    const interval = setInterval(() => {
      setTimer((draft) => {
        if (isNaN(draft) || draft <= 0) {
          clearInterval(intervalRef.current);
          return 0;
        }
        return draft - 1000;
      });
    }, 1000);
    intervalRef.current = interval;
    return () => clearInterval(intervalRef.current);
  }, [currentTime, startTime, setTimer]);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  return {
    timer,
  };
};
