import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAssessmentSchedule, GET_ASSESSMENT_SCHEDULE } from "..";

export const useAssessmentScheduleQuery = ({
  assessmentId = null,
  programId = null,
  scheduleMasterId = null,
  isModalOpen = false,
}) => {
  return useQuery({
    queryKey: [GET_ASSESSMENT_SCHEDULE, assessmentId, programId],
    queryFn: () =>
      getAssessmentSchedule({ assessmentId, programId, scheduleMasterId }),
    enabled: !!assessmentId && !!programId && !!scheduleMasterId && isModalOpen,
  });
};
