import React from "react";
import { LoadingSpinner } from "shared";
import parse from "html-react-parser";
import { useTermsAndCondition } from "..";

export const TermsCondition = () => {
  const { settingsInfo } = useTermsAndCondition({
    load: true,
    key: "page_terms",
  });
  const { data, isLoading } = settingsInfo;

  if (isLoading) return <LoadingSpinner />;

  return <p>{data ? parse(data?.content) : ""}</p>;
};
