import React, { useEffect } from "react";
import { PageLayout, LoadingSpinner } from "shared";
import { useImmer } from "use-immer";
import {
  QuestionItem,
  QuestionsModal,
  QuestionStatusBadge,
  QuestionLegend,
  AssessmentTimer,
  QuestionIndexMobileModal,
} from "..";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuestionareContext } from "master";
import tileIcon from "assets/images/icons/tile-menu-icon-grey.svg";
import prevIcon from "assets/images/icons/prev-icon-black.svg";
import nextIcon from "assets/images/icons/next-icon-black.svg";
import clearIcon from "assets/images/icons/close-icon-blue.svg";
import flagIcon from "assets/images/icons/flag-icon-blue.svg";

export const Questionnaire = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const assessmentId_q = searchParams.get("assessmentId");
  const slotId_q = searchParams.get("slotId");
  const attemptId_q = searchParams.get("attemptId");
  const assessmentToken_q = searchParams.get("assessmentToken");

  const isAssessmentStartedFromMobileApp =
    assessmentId_q && slotId_q && assessmentToken_q;

  useEffect(() => {
    if (isAssessmentStartedFromMobileApp) {
      sessionStorage.setItem("assessmentId", assessmentId_q);
      sessionStorage.setItem("slotId", slotId_q);
      sessionStorage.setItem("attemptId", attemptId_q);
      sessionStorage.setItem("assessmentToken", assessmentToken_q);
      sessionStorage.setItem("isFromMobileApp", true);
    }
  }, []);

  const [isOpenNavModal, setIsOpenNavModal] = useImmer(false);
  const questionnaireContext = useQuestionareContext();
  const {
    questionnaire,
    markedQuestions,
    currentQuestionIndex,
    onChangeSection,
    currentQuestion,
    onAnswer,
    onClearAnswer,
    onNext,
    onPrevious,
    onMarkQuestion,
    setIsModalOpen,
    startAssessmentQuery,
    onChangeSidebarButton,
    mediaPreviewState,
    answeringQuestion,
    clearAnswer,
    onToggleInstruction,
    onToggleHelp,
    answerPayload,
    clearPayload,
  } = questionnaireContext;

  const isLastSection =
    questionnaire?.sections?.length - 1 === currentQuestionIndex?.sectionIndex;
  const isLastQuestion =
    questionnaire?.questions?.length - 1 ===
    currentQuestionIndex?.questionIndex;
  const isFirstSection = currentQuestionIndex?.sectionIndex === 0;
  const isFirstQuestion = currentQuestionIndex?.questionIndex === 0;

  const { answer, questionId, sectionId } =
    !!currentQuestion && currentQuestion;

  const isClearingAnswer = clearAnswer?.isPending;
  const isAnswering = answeringQuestion?.isPending;

  const isMarked =
    markedQuestions &&
    markedQuestions?.some(
      (c) => c?.questionId === questionId && c?.sectionId === sectionId
    );

  const isClearingCurrentQuestion =
    answer &&
    answer?.id === clearPayload?.answerId &&
    answer?.sectionId === sectionId &&
    answer?.questionId === questionId;

  const isAnsweringCurrentQuestion =
    answerPayload?.sectionId === sectionId &&
    answerPayload?.questionId === questionId;

  const isDisabledClear =
    answer === null ||
    (isClearingCurrentQuestion && isClearingAnswer) ||
    (isAnsweringCurrentQuestion && isAnswering);

  if (startAssessmentQuery?.isLoading) return <LoadingSpinner />;

  return (
    <>
      <PageLayout isPageHeaderRequired={false}>
        <section className="question-page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="question-top-header">
                  <div className="question-header-btn-group">
                    {questionnaire &&
                      questionnaire?.sections?.map((section, index) => (
                        <button
                          key={`question-section-${section?.sectionId}`}
                          onClick={() => onChangeSection(section?.sectionId)}
                          className={`question-header-btn ${
                            currentQuestionIndex?.sectionIndex === index
                              ? "active"
                              : ""
                          }`}
                        >
                          {section?.name}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* INNER PAGE TASK SUB HEADER - MOB  */}
        <section className="question-page-sub-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="question-top-sub-header">
                  <button
                    onClick={() => setIsOpenNavModal(true)}
                    className="question-subheader-menu-btn"
                  >
                    <img src={tileIcon} />
                  </button>
                  <AssessmentTimer />
                  <button
                    onClick={() => navigate("/program/assessment/review")}
                    className="question-subheader-submit-btn"
                  >
                    Review/Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="question-page-content-wrapper">
          <div className="container">
            <div className="row">
              <div className="question-page-content-holder-block">
                <div className="question-content-wrapper scrollbar-style">
                  {currentQuestion && (
                    <QuestionItem
                      question={currentQuestion}
                      onAnswer={onAnswer}
                      mediaPreviewState={mediaPreviewState}
                      isClearingAnswer={isClearingAnswer}
                      isAnswering={isAnswering}
                      totalQuestions={questionnaire?.questions?.length}
                      answerPayload={answerPayload}
                    />
                  )}
                </div>

                <div className="question-content-footer">
                  <div className="action-block-btn-group">
                    <button
                      className="question-rounded-btn"
                      disabled={isDisabledClear}
                      onClick={() =>
                        onClearAnswer(answer?.id, questionId, sectionId)
                      }
                    >
                      <img src={clearIcon} />
                      <span>Clear</span>
                    </button>
                    <button
                      onClick={() => onMarkQuestion({ questionId, sectionId })}
                      className={`question-rounded-btn ${
                        isMarked ? "marked" : ""
                      }`}
                    >
                      <img src={flagIcon} />
                      <span>{isMarked ? "Unmark" : "Mark for Review"}</span>
                    </button>
                  </div>
                  <div className="action-block-right">
                    <button
                      onClick={onPrevious}
                      disabled={isFirstSection && isFirstQuestion}
                      className="question-nav-footer-btn question-nav-btn__prev"
                    >
                      <img src={prevIcon} />
                      <span>Previous</span>
                    </button>
                    <button
                      onClick={onNext}
                      disabled={isLastSection && isLastQuestion}
                      className="question-nav-footer-btn question-nav-btn__next"
                    >
                      <span>Next</span>
                      <img src={nextIcon} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="question-sidebar-block-wrapper">
                <div className="question-sidebar-holder">
                  <div className="question-sidebar-block">
                    <div className="question-sidebar-header">
                      <h4>{`Questions (${
                        questionnaire?.questions?.length ?? 0
                      })`}</h4>
                      <button
                        onClick={() =>
                          setIsModalOpen((draft) => {
                            draft = {
                              type: "allQuestions",
                              isOpen: true,
                            };
                            return draft;
                          })
                        }
                      >
                        All Questions
                      </button>
                    </div>
                    <div className="question-sidebar-content scrollbar-style">
                      <div className="question-nav-btn-group">
                        {questionnaire &&
                          questionnaire?.questions?.map((question, index) => {
                            return (
                              <QuestionStatusBadge
                                key={`questions-bubble-${question.questionId}-${index}`}
                                index={index}
                                question={question}
                                markedQuestions={markedQuestions}
                                currentQuestionIndex={currentQuestionIndex}
                                onSelect={() => {
                                  onChangeSidebarButton(
                                    question.sectionId,
                                    index
                                  );
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div className="question-sidebar-footer">
                      <div className="question-sidebar-legends">
                        <QuestionLegend type="toAttend" />
                        <QuestionLegend type="current" />
                        <QuestionLegend type="marked" />
                        <QuestionLegend type="completed" />
                      </div>
                      <div className="question-sidebar-footer-action">
                        <button
                          onClick={() => navigate("/program/assessment/review")}
                          className="btn btn-blue"
                        >
                          Review/Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- QUESTIONS FOOTER MOB --> */}

        <footer className="question-footer-mob">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="question-footer-block">
                  <button
                    onClick={onPrevious}
                    disabled={isFirstSection && isFirstQuestion}
                    className="question-rounded-nav-btn"
                  >
                    <img src={prevIcon} />
                  </button>
                  <div className="action-block-btn-group">
                    <button
                      disabled={isDisabledClear}
                      onClick={() =>
                        onClearAnswer(answer?.id, questionId, sectionId)
                      }
                      className="question-rounded-btn"
                    >
                      <img src={clearIcon} />
                      <span>Clear</span>
                    </button>
                    <button
                      onClick={() => onMarkQuestion({ questionId, sectionId })}
                      className={`question-rounded-btn ${
                        isMarked ? "marked" : ""
                      }`}
                    >
                      <img src={flagIcon} />
                      <span>{isMarked ? "Unmark" : "Mark for Review"}</span>
                    </button>
                  </div>
                  <button
                    onClick={onNext}
                    disabled={isLastSection && isLastQuestion}
                    className="question-rounded-nav-btn"
                  >
                    <img src={nextIcon} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <QuestionsModal />
        {isOpenNavModal && (
          <QuestionIndexMobileModal
            questionnaire={questionnaire}
            currentQuestionIndex={currentQuestionIndex}
            onChangeSection={onChangeSection}
            markedQuestions={markedQuestions}
            onChangeSidebarButton={onChangeSidebarButton}
            setIsOpenNavModal={setIsOpenNavModal}
            onToggleInstruction={onToggleInstruction}
            onToggleHelp={onToggleHelp}
          />
        )}
      </PageLayout>
    </>
  );
};
