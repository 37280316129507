import React, { useContext, useEffect, createContext } from "react";
import { useImmer } from "use-immer";
import { getCookie } from "utils";

const AppContext = createContext();

export const AppScope = (props) => {
  const initial = {
    isAuthenticated: false,
    user: "",
    token: "",
    registrationId: "",
    error: null,
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;
  }, []);

  const localData = JSON.parse(getCookie("_stu_user_data"));

  const [AppState, setAppState] = useImmer(localData || initial);
  const [isRemember, setIsRemember] = useImmer(
    JSON.parse(localStorage.getItem("_stu_is_remember")) || false
  );

  useEffect(() => {
    localStorage.setItem("_stu_is_remember", JSON.stringify(isRemember));
  }, [isRemember]);

  return (
    <AppContext.Provider
      value={{
        AppState,
        setAppState,
        isRemember,
        setIsRemember,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppScope = () => useContext(AppContext);
