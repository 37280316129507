import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SessionExpiredImg from "assets/images/error-session-expired-graphics.svg";
import { ErrorComponent, LoadingSpinner, MainFooter, PageLayout } from "shared";

export const SessionExpired = () => {
  const navigate = useNavigate();
  const isFromMobileApp = sessionStorage.getItem("isFromMobileApp");
  const brandLogo = process.env.REACT_APP_MAIN_LOGO;

  useEffect(() => {
    if (isFromMobileApp) {
      navigate("?tokenExpiredForMobileApp");
    }
  }, []);

  const goToLogin = () => {
    navigate("/login", { replace: true });
  };

  if (isFromMobileApp) {
    return <LoadingSpinner />;
  }

  return (
    <div id="app">
      <div id="main" className="layout-navbar">
        <div id="main-content">
          <header className="auth-header">
            <div className="container">
              <div className="row">
                <div className="header-block d-flex align-items-center justify-content-between">
                  <Link className="brand-logo" to="/login">
                    <img src={brandLogo} alt="nergy vidya" />
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <PageLayout isBreadCrumbsRequired={false}>
            <div className="row">
              <div className="col-12">
                <ErrorComponent
                  title={`Please Log in`}
                  message={`Your account has been signed in to another device or session expired.`}
                  image={SessionExpiredImg}
                  btnLabel="Go To Login"
                  onClick={goToLogin}
                />
              </div>
            </div>
          </PageLayout>
          <MainFooter />
        </div>
      </div>
    </div>
  );
};
