import React from "react";

export const NotificationBanner = ({
  notification,
  dateFormatted,
  viewAllNotification,
  trimContent,
}) => {
  const { title, content, localCreatedDate } = notification;

  return (
    <div className="notification-card col-6" onClick={viewAllNotification}>
      <div className="notification-card-header d-flex align-items-center justify-content-between">
        <h4>{title}</h4>
        <span className="timestamp">{dateFormatted(localCreatedDate)}</span>
      </div>
      <div className="notification-content">
        <p>{trimContent(content, 100)}</p>
      </div>
    </div>
  );
};
