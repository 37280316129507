import React, { useEffect } from "react";
import { PageLayout, ErrorComponent, LoadingSpinner } from "shared";
import SessionExpired from "assets/images/assessment-session-expired.svg";
import { useNavigate, useLocation } from "react-router-dom";

export const ExpiredPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const message = location?.state?.message;

  const isFromMobileApp = sessionStorage.getItem("isFromMobileApp");
  useEffect(() => {
    if (isFromMobileApp) {
      navigate("?tokenExpiredForMobileApp");
    }
  }, []);

  if (isFromMobileApp) {
    return <LoadingSpinner />;
  }

  const goToDashboard = () => {
    navigate("/program/assessment", { replace: true });
  };

  return (
    <PageLayout>
      <ErrorComponent
        title="Your session has expired!"
        message={message}
        image={SessionExpired}
        btnLabel="Go back to Dashboard"
        onClick={goToDashboard}
      />
    </PageLayout>
  );
};
