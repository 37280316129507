import React from "react";
import homeIcon from "assets/images/icons/home-icon.svg";
import { Link } from "react-router-dom";
import { trimText } from "utils";

export const PageHeader = ({
  breadcrumb,
  titleEndComponent,
  title,
  isBreadCrumbsMarginRequired,
  isBreadCrumbsRequired,
}) => {
  return (
    <div className="col-12">
      <div
        className={`breadcrumb-element ${
          title || !isBreadCrumbsMarginRequired ? "" : "inner-page-breadcrumb"
        }`}
      >
        {isBreadCrumbsRequired && (
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item" style={{ lineHeight: "1" }}>
                <Link to="/">
                  <img src={homeIcon} alt="" />
                </Link>
              </li>
              {breadcrumb?.map((item, idx) => {
                if (breadcrumb?.length <= 1) {
                  return (
                    <li key={idx} className="breadcrumb-item active">
                      {trimText(item?.label)}
                    </li>
                  );
                }
                return (
                  <li
                    key={idx}
                    className={`${
                      item?.location ? "" : "active"
                    } breadcrumb-item`}
                  >
                    {item?.location ? (
                      <Link to={item?.location}> {trimText(item?.label)}</Link>
                    ) : (
                      trimText(item?.label)
                    )}
                  </li>
                );
              })}
            </ol>
          </nav>
        )}
      </div>
      {title && (
        <div className="inner-page-header d-flex justify-content-between align-items-center">
          <div className="page-header-left">
            <h2 className="page-title">{title}</h2>
          </div>
          {titleEndComponent && (
            <div className="page-header-right">{titleEndComponent}</div>
          )}
        </div>
      )}
    </div>
  );
};
