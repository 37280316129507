export const strongPassword = {
  upper: {
    message: "Password must include atleast an uppercase letter",
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^(?=.*?[A-Z])/) && params.indexOf(val) === -1;
    },
  },
  lower: {
    message: "Password must include atleast a lowercase letter",
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^(?=.*?[a-z])/) && params.indexOf(val) === -1;
    },
  },
  digit: {
    message: "Password must include atleast a number",
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^(?=.*?[0-9])/) && params.indexOf(val) === -1;
    },
  },
  special: {
    message: "Password must include atleast a special character.",
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^(?=.*?[#?!@$%^&*-])/) && params.indexOf(val) === -1;
    },
  },
};
