import { useQuery } from "@tanstack/react-query";
import { GET_STUDENT_SPECIFIC_DATA, getStudentSpecificData } from "..";

export const useStudentSpecificDataQuery = ({
  load = false,
  programId = "",
  courseId = null,
}) => {
  return useQuery({
    queryKey: `${GET_STUDENT_SPECIFIC_DATA}_${programId}_${courseId}`,
    queryFn: () => getStudentSpecificData(programId, courseId),
    enabled: load && programId !== "" && courseId !== null,
  });
};
