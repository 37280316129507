import React from "react";
import Slider from "react-slick";
import speaker from "assets/images/icons/speaker-filled-brown.svg";

export const NotificationModal = ({
  notificationsBanner,
  setCloseNotification,
  dateFormatted,
  trimContent,
  viewAllNotification,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="notification-sm-modal">
      <div className="notification-modal-content">
        <span
          className="modal-close-span"
          onClick={() =>
            setCloseNotification((draft) => {
              draft = false;
              return draft;
            })
          }
        ></span>
        <div className="notification-slides slick-slider">
          <Slider {...settings}>
            {notificationsBanner?.data?.slice(0, 3)?.map((item) => (
              <div
                className="notification-slide"
                key={`Notification Modal${item.id}`}
                onClick={viewAllNotification}
              >
                <img src={speaker} />
                <h4>{item.title}</h4>
                <span className="timestamp">
                  {dateFormatted(item.localCreatedDate)}
                </span>
                <p className="text-break">{trimContent(item.content, 100)}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
