import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ProgramDetails } from ".";
import "./styles/programDetails.style.css";
import { LearningMaterials, Assessment, Certificate, Help } from "modules";
import { PageNotFound } from "master";

export const Program = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("selectedProgram")) {
      navigate("/");
    }
  });

  return (
    <Routes>
      <Route element={<ProgramDetails />} path={"/"} />
      <Route element={<LearningMaterials type={1} />} path={"/practicals/*"} />
      <Route element={<LearningMaterials type={2} />} path={"/assignment/*"} />
      <Route
        element={<LearningMaterials type={0} />}
        path={"/learningmaterials/*"}
      />
      <Route element={<Assessment />} path={"/assessment/*"} />
      <Route element={<PageNotFound />} path={"/*"} />
      <Route exact element={<Help />} path={"/help"} />
      <Route
        element={<Certificate isAllCertificate={false} />}
        path={"/certificate/*"}
      />
    </Routes>
  );
};
