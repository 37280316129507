import { useQuery } from "@tanstack/react-query";
import {
  GET_FILTERED_NOTIFICATIONS,
  GET_UNFILTERED_NOTIFICATIONS,
  getFilteredNotifications,
} from "..";

export const useNotificationBannerQuery = ({
  unreadOnly = false,
  load = false,
}) => {
  const notificationsBanner = useQuery({
    queryKey: unreadOnly
      ? [GET_FILTERED_NOTIFICATIONS]
      : [GET_UNFILTERED_NOTIFICATIONS],
    queryFn: () => getFilteredNotifications(unreadOnly),
    enabled: load,
    gcTime: 1000,
  });
  return {
    notificationsBanner,
  };
};
