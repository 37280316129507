import LinkIcon from "assets/images/icons/link-icon.svg";

export const ExternalLinkContent = ({ label, redirectTo }) => {
  return (
    <a className="attachement-btn" href={redirectTo} target="_blank">
      <img src={LinkIcon} alt="icon" />
      <span className="attachement-btn-txt">{label}</span>
    </a>
  );
};
