import React from "react";
import { DashboardBanner, DashboardPrograms } from "..";
import { PageLayout } from "shared";

export const LandingPage = () => {
  return (
    <PageLayout
      isBreadCrumbsRequired={false}
      isBreadCrumbsMarginRequired={false}
    >
      <div className="col-12">
        <DashboardBanner />
      </div>
      <div className="col-12">
        <DashboardPrograms />
      </div>
    </PageLayout>
  );
};
