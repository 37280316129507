import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ProfileDropDown,
  NotificationDropDown,
  ChangePassword,
  AssessmentTimer,
} from "modules";
import { HeaderLink, ModalLayout, useProfileDropDown } from "shared";
import { useQuestionareContext, useAppScope, useAuthenticate } from "master";
import NotificationIcon from "assets/images/icons/notifiction-icon.svg";
import UserIcon from "assets/images/icons/user-icon.svg";
import InstructionIcon from "assets/images/icons/book-dark-grey-thick-icon.svg";
import HelpIcon from "assets/images/icons/question-icon-thick-grey.svg";
import warningIcon from "assets/images/icons/warning-round-filled-blue.svg";

export const HeaderRightBlock = () => {
  const location = useLocation();
  const questionnaireContext = useQuestionareContext();
  const { signOutFromAccount } = useAuthenticate();
  const { onToggleInstruction, onToggleHelp } = questionnaireContext;
  const isQuestionnairePages =
    location.pathname === "/program/assessment/start" ||
    location.pathname === "/program/assessment/review" ||
    location.pathname === "/program/assessment/result" ||
    location.pathname === "/program/assessment/expired" ||
    location.pathname === "/program/assessment/allQuestions";

  const isAssesmentResult = location.pathname === "/program/assessment/result";

  const { AppState } = useAppScope();
  const {
    user: { firstName, lastName },
    registrationId,
  } = AppState;

  const studentName = `${firstName} ${lastName ? lastName : ""}`;

  const { modalState, onOpenModal, onCloseModal, profileQuery } =
    useProfileDropDown({ load: !isQuestionnairePages });

  useEffect(() => {
    onCloseModal();
  }, [location.pathname]);

  return (
    <>
      {isQuestionnairePages ? (
        <>
          {!isAssesmentResult && (
            <>
              <AssessmentTimer />
              <div className="header-info-group">
                <button
                  className="header-info-item"
                  onClick={() => onToggleInstruction(null, "header")}
                >
                  <img src={InstructionIcon} />
                </button>
                <button
                  className="header-info-item"
                  onClick={() => onToggleHelp()}
                >
                  <img src={HelpIcon} />
                </button>
              </div>
              <div className="user-info-block">
                <h3 className="user-name">{studentName}</h3>
                {!!registrationId && (
                  <span className="user-reg-number">{`Reg No:${registrationId}`}</span>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <HeaderLink icon={NotificationIcon} location={location}>
            <NotificationDropDown />
          </HeaderLink>
          <HeaderLink
            icon={UserIcon}
            location={location}
            closeDropDown={modalState.isOpen}
          >
            <ProfileDropDown
              onOpenModal={onOpenModal}
              profileQuery={profileQuery}
            />
          </HeaderLink>
          {modalState.actionFrom == "changePassword" && (
            <ChangePassword
              openChangePassword={modalState.isOpen}
              onCloseModal={onCloseModal}
            />
          )}
          {modalState.actionFrom === "logoutModal" && (
            <ModalLayout
              isOpen={modalState.isOpen}
              title={"Confirm logout"}
              message={"Are you sure you want to log out?"}
              icon={warningIcon}
              onCancel={onCloseModal}
              modalButtonGroup={
                <div className="button-group d-flex justify-content-center align-items-center">
                  <button className="btn btn-secondary" onClick={onCloseModal}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={signOutFromAccount.isPending}
                    onClick={() => {
                      signOutFromAccount.mutate();
                    }}
                  >
                    Log out
                  </button>
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
};
