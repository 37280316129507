import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getQuestionnaire, START_ASSESSMENT } from "..";

export const useStartAssessmentQuery = ({
  enabled,
  assessmentId,
  slotId,
  attemptId,
  assessmentTokenState,
}) => {
  return useQuery({
    queryKey: [START_ASSESSMENT, assessmentId],
    queryFn: () =>
      getQuestionnaire({
        assessmentId,
        slotId,
        attemptId,
        assessmentTokenState,
      }),
    enabled,
  });
};
