import React from "react";
import ResultIcon from "assets/images/icons/result-md-icon.svg";

export const PublishSoonCard = ({ message, goToDashboard }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="publish-soon-wrap">
            <div className="assessment-result-board">
              <img src={ResultIcon} alt="icon" />
              <p>{message}</p>
              <div className="action-block">
                <button className="btn btn-blue" onClick={goToDashboard}>
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
