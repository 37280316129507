import React from "react";
import { MainHeader, PageLayout } from "shared";
import { PrivacyAndPolicy } from ".";

export const Privacy = () => {
  return (
    <>
      <MainHeader />
      <PageLayout
        title="Privacy Policy for Nergy India Pvt Ltd"
        breadcrumb={[{ label: "Privacy And Policy" }]}
      >
        <PrivacyAndPolicy />
      </PageLayout>
    </>
  );
};
