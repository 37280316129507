import React, { useRef, useState, useEffect, memo } from "react";

export const HeaderLink = memo(
  ({ icon, onClick = null, children, location, closeDropDown = false }) => {
    const menuLabelRef = useRef();
    const menuRef = useRef();

    const [isShow, setIsShow] = useState(false);

    const toggleMenu = () => {
      setIsShow((prevIsShow) => !prevIsShow);
    };

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          menuLabelRef.current &&
          !menuLabelRef.current.contains(event.target) &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsShow(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      setIsShow(false);
    }, [location, closeDropDown]);

    return (
      <div>
        <a
          className="header-action-btn"
          ref={menuLabelRef}
          onClick={!!children ? toggleMenu : onClick}
        >
          <img src={icon} />
        </a>
        {isShow && <div ref={menuRef}>{children}</div>}
      </div>
    );
  }
);
