import React, { useCallback, useMemo } from "react";
import parse from "html-react-parser";
import { MediaPreviewModal } from "shared";

export const QuestionItem = ({
  question,
  onAnswer,
  mediaPreviewState,
  totalQuestions,
  isClearingAnswer,
  isAnswering,
  answerPayload,
}) => {
  const {
    description,
    choices,
    attachments,
    answer,
    sectionId,
    questionNumber,
    questionId,
  } = question;

  const isAnsweringCurrentQuestion =
    answerPayload?.sectionId === sectionId &&
    answerPayload?.questionId === questionId;

  const { toggleModal, onSetUrl, modalState } = mediaPreviewState;

  const onMediaClick = useCallback(
    (url, mediaType) => {
      if (url) {
        onSetUrl({ url, mediaType });
      }
    },
    [attachments]
  );

  const formattedChoices = useMemo(() => {
    return (
      choices?.length > 0 &&
      [...choices]?.sort((b, a) => {
        if (a?.code < b?.code) return 1;
        if (a?.code > b?.code) return -1;
        return 0;
      })
    );
  }, [choices]);

  const renderMediaByType = useCallback(
    (url, type) => {
      switch (type) {
        case 0:
          return url ? (
            <>
              <div className="attachment-overlay">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <img src={url} alt="media" />
            </>
          ) : (
            <span className="no-attachment-msg">No media found</span>
          );
        case 1:
          return url ? (
            <>
              <div className="attachment-overlay">
                <i className="bi bi-play-circle"></i>
              </div>
              <video width="100%" height="auto" controls={false}>
                <source src={`${url}#t=0.2`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          ) : (
            <span className="no-attachment-msg">No media found</span>
          );
        default:
          return null;
      }
    },
    [attachments]
  );

  return (
    <>
      <div className="question-count-label">{`${questionNumber} of ${totalQuestions}`}</div>
      <div className="question-content-view">
        <div> {description && parse(description)}</div>
        <div className="question-attachment-group mt-4 mb-4">
          {attachments &&
            attachments?.length > 0 &&
            attachments?.map((attachment) => {
              const {
                questionAttachmentName,
                questionAttachmentsUrls,
                contentType,
              } = attachment;
              return (
                <div
                  key={questionAttachmentName}
                  onClick={() =>
                    onMediaClick(questionAttachmentsUrls, contentType)
                  }
                  className="question-attachment"
                >
                  {renderMediaByType(questionAttachmentsUrls, contentType)}
                </div>
              );
            })}
        </div>

        <ul className="question-options-list">
          {formattedChoices &&
            formattedChoices?.map((q, idx) => {
              return (
                <li
                  key={`choice-item-${sectionId}-${q?.choiceId}-${idx}`}
                  className="question-option"
                >
                  <input
                    type="radio"
                    id={`choice-${sectionId}-${q?.choiceId}-${idx}`}
                    name={`choice-${q?.questionId}`}
                    value={q.choiceId}
                    checked={q.choiceId === answer?.choiceId}
                    disabled={
                      (isAnsweringCurrentQuestion && isAnswering) ||
                      (isAnsweringCurrentQuestion && isClearingAnswer)
                    }
                    onChange={() =>
                      onAnswer({
                        questionId: q?.questionId,
                        choiceId: q?.choiceId,
                        sectionId,
                      })
                    }
                  />
                  <label htmlFor={`choice-${sectionId}-${q?.choiceId}-${idx}`}>
                    <span>{`${q?.code}). ${q?.description}`}</span>
                  </label>
                  <div className="check"></div>
                </li>
              );
            })}
        </ul>
      </div>

      <MediaPreviewModal
        isOpen={modalState.isModalOpen}
        url={modalState.url}
        mediaType={modalState.mediaType}
        onCancel={() => toggleModal()}
      />
    </>
  );
};
