import React, { useMemo } from "react";
import parse from "html-react-parser";
import { useImmer } from "use-immer";
import { QuestionStatusBadge } from "..";

export const QuestionModalItem = ({
  question,
  index,
  navigateQuestionHandle,
  markedQuestions,
}) => {
  const { description, choices, answer, sectionId } = question;
  const [showMore, isShowMore] = useImmer(false);

  const formattedChoices = useMemo(() => {
    return (
      choices?.length > 0 &&
      [...choices]?.sort((b, a) => {
        if (a?.code < b?.code) return 1;
        if (a?.code > b?.code) return -1;
        return 0;
      })
    );
  }, [choices]);

  return (
    <li className="modal-question-item">
      <div
        className="question-item-content-holder"
        onClick={() =>
          showMore
            ? navigateQuestionHandle(question?.questionNumber, sectionId)
            : isShowMore(!showMore)
        }
      >
        <div className="modal-question-index">
          <QuestionStatusBadge
            index={index}
            question={question}
            markedQuestions={markedQuestions}
          />
        </div>

        <div className="modal-question-content">
          <div className="question-content-view">
            <div>{!!description && parse(description)}</div>
            {showMore && (
              <ul className="question-options-list">
                {formattedChoices &&
                  formattedChoices?.map((q, idx) => {
                    return (
                      <li
                        key={`modal-choice-item-${sectionId}-${q?.choiceId}-${idx}`}
                        className="question-option"
                      >
                        <input
                          type="radio"
                          id={`modal-choice-${sectionId}-${q?.choiceId}-${idx}`}
                          name={`modal-choice-${q?.questionId}`}
                          value={q.choiceId}
                          checked={q.choiceId === answer?.choiceId}
                          disabled={true}
                        />
                        <label
                          htmlFor={`modal-choice-${sectionId}-${q?.choiceId}-${idx}`}
                        >
                          <span>{`${q?.code}). ${q?.description}`}</span>
                        </label>
                        <div className="check"></div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="modal-question-toggle">
        <button
          onClick={() => isShowMore(!showMore)}
          className={`question-toggle-btn ${
            showMore ? "toggle__open" : "toggle__close"
          }`}
        ></button>
      </div>
    </li>
  );
};
