import { useImmer } from "use-immer";
import { resetPassword } from "..";
import { useMutation } from "@tanstack/react-query";
import { errorFormatter } from "utils";
import { useModal } from "shared";

export const useResetPassword = () => {
  const { modalState, onOpenModal, onCloseModal } = useModal();
  const [payload, setPayload] = useImmer({
    password: "",
    confirmPassword: "",
  });

  const mutationFn = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      onOpenModal({
        actionFrom: "passwordReset",
      });
    },
    onError: (e) => {
      errorFormatter(e);
    },
  });

  return {
    payload,
    setPayload,
    mutationFn,
    modalState,
    onCloseModal,
  };
};
