import closeIcon from "assets/images/icons/close-icon-round-filled-grey.svg";
import questionIcon from "assets/images/icons/question-icon-thick-black-2.svg";
import bookIcon from "assets/images/icons/book-dark-black-thick-icon.svg";
import { QuestionLegend, QuestionStatusBadge } from "..";
import { useNavigate } from "react-router-dom";

export const QuestionIndexMobileModal = ({
  questionnaire,
  currentQuestionIndex,
  onChangeSection,
  markedQuestions,
  onChangeSidebarButton,
  setIsOpenNavModal,
  onToggleInstruction,
  onToggleHelp,
}) => {
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpenNavModal(false);
  };

  const goToAllQuestions = () => {
    navigate("/program/assessment/allQuestions");
  };

  return (
    <div className="modal-question-index-sm-device">
      <div className="question-modal-overlay" onClick={closeModal}></div>
      <div className="question-modal-content">
        <button onClick={closeModal} className="question-modal-close-btn">
          <img src={closeIcon} />
        </button>
        <div className="question-modal-header">
          <h3>{questionnaire?.name}</h3>
          <div className="header-info-group">
            <button
              onClick={() => {
                onToggleHelp();
                closeModal();
              }}
              className="header-info-item"
            >
              <img src={questionIcon} />
            </button>
            <button
              className="header-info-item"
              onClick={() => {
                onToggleInstruction(null, "header");
                closeModal();
              }}
            >
              <img src={bookIcon} />
            </button>
          </div>
        </div>
        <div className="question-content-block">
          <div className="question-sections-group">
            <div className="question-header-btn-group scrollbar-style-horizontal">
              {questionnaire &&
                questionnaire?.sections?.map((section, index) => (
                  <button
                    key={`question-mob-modal-section-${section?.sectionId}-${index}`}
                    onClick={() => onChangeSection(section?.sectionId)}
                    className={`question-header-btn ${
                      currentQuestionIndex?.sectionIndex === index
                        ? "active"
                        : ""
                    }`}
                  >
                    {section?.name}
                  </button>
                ))}
            </div>
          </div>
          <div className="question-sidebar-block">
            <div className="question-sidebar-header">
              <h4>{`Questions (${questionnaire?.questions?.length ?? 0})`}</h4>
              <button onClick={goToAllQuestions}>All Questions</button>
            </div>
            <div className="question-sidebar-content scrollbar-style">
              <div className="question-nav-btn-group">
                {questionnaire &&
                  questionnaire?.questions?.map((question, index) => {
                    return (
                      <QuestionStatusBadge
                        key={`questions-mob-modal-bubble-${question.questionId}-${index}`}
                        index={index}
                        question={question}
                        markedQuestions={markedQuestions}
                        currentQuestionIndex={currentQuestionIndex}
                        onSelect={() => {
                          onChangeSidebarButton(question.sectionId, index);
                          closeModal();
                        }}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="question-sidebar-footer">
              <div className="question-sidebar-legends">
                <QuestionLegend type="toAttend" />
                <QuestionLegend type="current" />
                <QuestionLegend type="marked" />
                <QuestionLegend type="completed" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
