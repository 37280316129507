import React from "react";
import { useHelp } from "..";
import { ErrorComponent, LoadingSpinner, PageLayout } from "shared";
import { useImmer } from "use-immer";
import parse from "html-react-parser";
import "../styles/help.styles.css";
import ErrorImg from "assets/images/error-something-went-wrong-graphics.svg";
import { useNavigate } from "react-router-dom";

export const HelpView = () => {
  const navigate = useNavigate();
  const { id, name } = localStorage.getItem("selectedProgram")
    ? JSON.parse(localStorage.getItem("selectedProgram"))
    : null;

  const [activeTabIndex, setActiveTabIndex] = useImmer(0);

  const { programHelp } = useHelp({ load: true, programId: id });
  const { data: helpData, isLoading, isFetching } = programHelp;
  const titles =
    helpData &&
    helpData?.map((tab, i) => {
      return (
        <li
          key={i}
          className={` ${i === activeTabIndex ? "active" : ""}`}
          onClick={() => setActiveTabIndex(i)}
        >
          {tab?.name}
        </li>
      );
    });

  const contents =
    helpData &&
    helpData
      ?.filter((_, i) => i === activeTabIndex)
      .map((tab, i) => {
        return (
          <div className="content-wrapper ql-editor p-0" key={i}>
            {tab?.content && parse(tab?.content)}
          </div>
        );
      });

  const goToPreviousPage = () => {
    navigate(-1, { replace: true });
  };

  if (isLoading || isFetching) return <LoadingSpinner />;

  return (
    <PageLayout
      title=""
      breadcrumb={[{ label: name, location: "/program" }, { label: "Help" }]}
    >
      <div className="row">
        {helpData?.length > 0 ? (
          <div className="col-12">
            <div className="help-page-search-wrapper d-flex flex-wrap">
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <h3>How Can We Help You?</h3>
              </div>
            </div>
            <div className="help-page-content-block d-flex flex-wrap">
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <ul className="help-side-nav">{titles}</ul>
              </div>
              <div className="help-page-content col-12 col-md-9 ps-md-3">
                <div className="content-holder">{contents}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="help-empty-message">
            <ErrorComponent
              title={`No Help Page Found!`}
              message={`There is no help page found In ${name} program.`}
              image={ErrorImg}
              btnLabel="Go back"
              onClick={goToPreviousPage}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
};
