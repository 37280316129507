import React from "react";

export const StudentSpecificDataBtn = ({
  onOpenModal,
  studentDataDisplayName = "",
}) => {
  return (
    <button
      className="btn btn-outline-blue-btn rounded-pill me-2 mt-1"
      onClick={() => onOpenModal({ actionFrom: "student_specific_data" })}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3C9.52441 3 7.5 5.02441 7.5 7.5C7.5 8.95312 8.20605 10.2393 9.28125 11.0625C8.07422 11.5898 7.0752 12.4834 6.35156 13.5938C5.71582 13.8105 5.23535 14.4346 5.34375 15.1641H5.32031L5.50781 16.5703C4.51172 16.8018 3.75 17.6895 3.75 18.75C3.75 19.9834 4.7666 21 6 21H18C19.2334 21 20.25 19.9834 20.25 18.75C20.25 17.6543 19.4414 16.7402 18.3984 16.5469L18.5859 15.1875C18.5859 15.1787 18.5859 15.1729 18.5859 15.1641C18.6885 14.4785 18.2725 13.8926 17.6953 13.6406C16.9717 12.5391 15.9492 11.6338 14.7188 11.0859C15.7998 10.2627 16.5 8.95898 16.5 7.5C16.5 5.02441 14.4756 3 12 3ZM12 4.5C13.667 4.5 15 5.83301 15 7.5C15 9.16699 13.667 10.5 12 10.5C10.333 10.5 9 9.16699 9 7.5C9 5.83301 10.333 4.5 12 4.5ZM12 12C13.418 12 14.707 12.5859 15.6562 13.5H8.29688C9.24902 12.5537 10.5498 12 12 12ZM6.82031 15H17.1094L16.5 19.4766V19.5H7.42969C7.43848 19.5 7.42969 19.4795 7.42969 19.4766L6.82031 15ZM15.75 15.75C15.3369 15.75 15 16.0869 15 16.5C15 16.9131 15.3369 17.25 15.75 17.25C16.1631 17.25 16.5 16.9131 16.5 16.5C16.5 16.0869 16.1631 15.75 15.75 15.75ZM18.2109 18.0469C18.5186 18.1406 18.75 18.4102 18.75 18.75C18.75 19.166 18.416 19.5 18 19.5L18.2109 18.0469ZM5.71875 18.0703L5.90625 19.4766C5.54004 19.4268 5.25 19.1309 5.25 18.75C5.25 18.4395 5.44922 18.1846 5.71875 18.0703Z"
          fill="#2760AD"
        />
      </svg>
      {studentDataDisplayName}
    </button>
  );
};
