import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useImmer } from "use-immer";

export const useCountdown = ({ validTill, currentTime }) => {
  const intervalRef = useRef();

  const calculateTimeDifference = (valid, current) => {
    const validAt = moment(valid).format("YYYY-MM-DDTHH:mm:ss");
    const currTime = moment(current).format("YYYY-MM-DDTHH:mm:ss");
    return new Date(validAt) - new Date(currTime);
  };

  const [countDown, setCountDown] = useImmer(null);

  const [timer, setTimer] = useImmer({
    hours: null,
    minutes: null,
    seconds: null,
  });

  useEffect(() => {
    if (validTill && currentTime) {
      clearInterval(intervalRef.current);

      const newCountDown = calculateTimeDifference(validTill, currentTime);
      setCountDown(newCountDown);

      const interval = setInterval(() => {
        setCountDown((draft) => {
          if (isNaN(draft) || draft <= 0) {
            clearInterval(intervalRef.current);
            return 0;
          }
          return draft - 1000;
        });
      }, 1000);

      intervalRef.current = interval;
      return () => clearInterval(intervalRef.current);
    }
  }, [validTill, currentTime, setCountDown]);

  useEffect(() => {
    if (countDown !== null && countDown > 0) {
      const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
      setTimer({ hours, minutes, seconds });
    } else if (countDown === 0) {
      setTimer({ hours: 0, minutes: 0, seconds: 0 });
    }
  }, [countDown, setTimer]);

  useEffect(() => {
    if (countDown !== null && countDown <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [countDown]);

  return { timer };
};
