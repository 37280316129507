import React from "react";
import moment from "moment";
import greenCheck from "assets/images/icons/check-green-filled-bordered.svg";
import redClose from "assets/images/icons/close-red-filled-bordered.svg";
import calendarIcon from "assets/images/icons/calendar-icon-assessment.svg";
import timerIcon from "assets/images/icons/timer-icon-assessment.svg";
import { ConfettiEffect } from ".";

export const ResultCard = ({ assessmentResult, goToDashboard }) => {
  const {
    assessmentName,
    maximumScore,
    obtainedScore,
    startedAt,
    endedAt,
    timeTaken,
    assessmentSchedule,
    message,
    resultStatus,
  } = assessmentResult;

  const isPassedAssessment = resultStatus === 4;
  const isAvailableScore =
    obtainedScore >= 0 &&
    typeof obtainedScore === "number" &&
    maximumScore >= 0 &&
    typeof maximumScore === "number";

  return (
    <>
      <section className="assessment-result-page-wrapper">
        <div
          className={`assessment-result-page-banner ${
            isPassedAssessment ? "assessment__passed" : "assessment__failed"
          }`}
        >
          {isPassedAssessment && <ConfettiEffect />}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="assessment-status-block">
                  {isPassedAssessment ? (
                    <img className="status-icon" src={greenCheck} />
                  ) : (
                    <img className="status-icon" src={redClose} />
                  )}
                  <h3 className="message-txt">
                    {isPassedAssessment
                      ? "Congratulations. You've Passed"
                      : "You’ve Failed."}
                  </h3>
                  <h4 className="sub-txt">{assessmentName}</h4>
                  <div className="sm-card-group">
                    <div className="sm-card-item">
                      <img src={calendarIcon} />
                      <span>
                        {moment(assessmentSchedule).format("DD MMMM YYYY")}
                      </span>
                    </div>
                    <div className="sm-card-item">
                      <img src={timerIcon} />
                      <span>{`${startedAt} to ${endedAt}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="assessment-result-board">
                {message && <p>{message}</p>}
                <div className="info-blocks">
                  {isAvailableScore && (
                    <div className="info-row">
                      <div className="info-label">Your Score</div>
                      <div className="info-value">{`${obtainedScore}/${maximumScore}`}</div>
                    </div>
                  )}
                  {timeTaken && (
                    <div className="info-row">
                      <div className="info-label">Time Taken</div>
                      <div className="info-value">{timeTaken}</div>
                    </div>
                  )}
                </div>
                <div className="action-block">
                  <button className="btn btn-blue" onClick={goToDashboard}>
                    Go to Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
