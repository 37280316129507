import { Axios } from "utils";

export const getProfile = async () => {
  const res = await Axios.get("v2/portal/Students/profile");
  return res.data;
};

export const changePassword = async (auth) => {
  const res = await Axios.post("/v2/accounts/change-password", auth);
  return res.data;
};
