import React, { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { ModalLayout } from "shared";
import { strongPassword } from "utils";
import { ChangePasswordModalBody } from ".";
import { usePassword } from "..";
import lockIcon from "assets/images/icons/lock-icon.svg";
import "../styles/changePassword.styles.css";

export const ChangePassword = ({ openChangePassword, onCloseModal }) => {
  const [update, forceUpdate] = useState();
  const {
    handleBack,
    showPassWord,
    handlePassword,
    changePasswordAuth,
    changePasswordPayload,
    setChangePasswordPayload,
  } = usePassword({ onCloseModal });

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: strongPassword,
    })
  );

  const onsubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      changePasswordAuth.mutate(changePasswordPayload);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <ModalLayout
      isOpen={openChangePassword}
      icon={lockIcon}
      iconClass={"modal-icon-small"}
      largeTitle="Change Password"
      message="Create new password for your account"
      modalBody={
        <ChangePasswordModalBody
          validator={validator}
          forceUpdate={forceUpdate}
          showPassWord={showPassWord}
          handlePassword={handlePassword}
          changePasswordPayload={changePasswordPayload}
          setChangePasswordPayload={setChangePasswordPayload}
        />
      }
      modalButtonGroup={
        <div className="modal-action btn_password">
          <div className="button-group d-flex justify-content-center align-items-center">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => onsubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      }
    />
  );
};
